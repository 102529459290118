import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign:'center',

    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct verbs to complete the sentences.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "261px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "495px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-13", "1-7", "11-2", "12-3", "4-8", "5-9", "10-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
          
            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;be an adult<br>
                    <b>2</b>&ensp;be a centenarian<br>
                    <b>3</b>&ensp;be a toddler<br>
                    <b>4</b>&ensp;be an infant<br>
                    <b>5</b>&ensp;be elderly<br>
                    <b>6</b>&ensp;be in your teens<br>
                    <b>7</b>&ensp;be middle-aged<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;100+<br>
                    <b>b</b>&ensp;60+<br>
                    <b>c</b>&ensp;13–19<br>
                    <b>d</b>&ensp;40–60<br>
                    <b>e</b>&ensp;1–3<br>
                    <b>f</b>&ensp;0–1<br>
                    <b>g</b>&ensp;18+<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 8,
    },
    // stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "addicted",
          "aware",
          "curious",
          "good",
          "happy",
          "obsessed",
          "sensitive",
          "shocked",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the adjectives and prepositions
below. You can use the prepositions more than once.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
          <div style='width:26cm'>
              <b>1</b>&ensp;Harry is # <input width=100px > money and he’s always thinking of ways of making it.<br>
              <b>2</b>&ensp;We weren’t # <input width=100px > the hotel, and nor were we pleased with the way the tour company handled the problem.<br>
              <b>3</b>&ensp;Don’t say anything negative about her work because she’s very # <input width=100px > criticism.<br>
              <b>4</b>&ensp;Children are # <input width=100px > everything around them and want to know how things work.<br>
              <b>5</b>&ensp;My grandparents would be offended by the language used on TV and # <input width=100px > the violence in films.<br>
              <b>6</b>&ensp;I’m not very # <input width=100px > maths and always get low marks in the exams.<br>
              <b>7</b>&ensp;She says she isn’t # <input width=100px > chocolate, but I always see her eating it!<br>
              <b>8</b>&ensp;I didn’t realise there was a problem, and I wasn’t # <input width=100px > the risks.<br>
          </div>
          
          `,
        answer: [
          "obsessed",
          "with",
          "happy",
          "about|with",
          "sensitive",
          "to",
          "curious",
          "about",
          "shocked",
          "by",
          "good",
          "at",
          "addicted",
          "to",
          "aware",
          "of",
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Put the words below in the correct columns to form different life events.`,
        color: "black",
        left: 50,
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
    <table style="width: 80%; border-collapse: collapse;">
        <tr>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">get</td>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">leave</td>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">start</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=0 rows=2 ></textarea> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=1 rows=2 ></textarea> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=2 rows=2 ></textarea> </td>
        </tr>
    </table>
          
          `,
        answer: [
          "divorced, engaged, married",
          "home, school",
          "a business, a family, school",
        ],
      },
    ],
  },
  4: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct form of the verbs below.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 220, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "emigrate",
          "inherit",
          "learn to drive",
          "retire",
          "settle down",
          "split up",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
    <div style='width:20cm'>
            <b>1</b>&ensp;My dad is looking forward to <u>finishing work</u> next year and having more time for his hobbies.  #<br>
            <b>2</b>&ensp;When my grandad passed away, my mum <u>got</u> his house and some money. #<br>
            <b>3</b>&ensp;My sister is <u>having driving lessons</u>. #<br>
            <b>4</b>&ensp;My uncle and aunt <u>separated</u> last year after being married for fifteen years. #<br>
            <b>5</b>&ensp;I don’t want to <u>get married and have a family</u> until I’ve had a chance to see the world. #<br>
            <b>6</b>&ensp;My older brother <u>moved to another country</u> last year to look for work. #<br>
    </div>
          
          `,
        answer: [
          "retiring",
          "inherited",
          "learning to drive",
          "split up",
          "settle down",
          "emigrated",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -70,
      marginLeft: 20,
      width: 700,
      paddingTop: 3,
    },
    stylesTextInput: { width: 220, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "buy a house or flat",
          "get divorced",
          "get married",
          "go to university",
          "grow up",
          "pass away",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sequences of events with the phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='line-height:45px'>
          <b>1</b>&ensp;retire, become a grandparent,<input width=251px /><br>
          <b>2</b>&ensp;fall in love, get engaged,<input width=318px /><br>
          <b>3</b>&ensp;start school, leave school,<input width=300px /><br>
          <b>4</b>&ensp;get married, split up,<input width=354px /><br>
          <b>5</b>&ensp;be born, be brought up,<input width=319px /><br>
          <b>6</b>&ensp;leave home, move house,<input width=300px /><br>
          
        </div>
          `,
        answer: [
          "pass away",
          "get married",
          "go to university",
          "get divorced",
          "grow up",
          "buy a house or flat",
        ],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "be",
          "buy",
          "career",
          "fall",
          "get",
          "job",
          "leave",
          "married",
          "settle",
          "split",
          "start",
          "study",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct form of the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page6/E6/1.jpg" style='height:23cm'/>
            <div style='position:absolute;top:385px;left:48px'>#</div>
            <div style='position:absolute;top:419px;left:48px'>#</div>
            <div style='position:absolute;top:419px;left:457px'>#</div>
            <div style='position:absolute;top:452px;left:195px'>#</div>
            <div style='position:absolute;top:486px;left:48px'>#</div>
            <div style='position:absolute;top:519px;left:48px'>#</div>
            <div style='position:absolute;top:586px;left:48px'>#</div>
            <div style='position:absolute;top:620px;left:82px'>#</div>
            <div style='position:absolute;top:654px;left:166px'>#</div>
            <div style='position:absolute;top:721px;left:166px'>#</div>
            <div style='position:absolute;top:721px;left:467px'>#</div>
            <div style='position:absolute;top:788px;left:53px'>#</div>
            
          </div>
          
          
          `,
        answer: [
          "left",
          "job",
          "started",
          "got",
          "married",
          "split",
          "study",
          "fell",
          "was",
          "bought",
          "settled",
          "career",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "12WB-2024-I-P6-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page6/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
