import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "agility",
          "career",
          "secure",
          "alternative",
          "confidence",
          "bewildering",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>
       <div style='width:26cm'>
        <b>1</b>&ensp;Having # is crucial in job interviews
        because it shows employers that you believe in your
        abilities.<br>
        <b>2</b>&ensp;Choosing a # path can be exciting, as
        it lets you explore your interests and talents in a
        professional setting.<br>
        <b>3</b>&ensp;To your parents, it’s important to have a #
        job that not only pays well but also
        brings you satisfaction and growth opportunities.<br>
        <b>4</b>&ensp;Considering an # career can be a great
        option if you find that your current job no longer
        fulfills you.<br>
        <b>5</b>&ensp;The array of career choices available today can be #
        , making it hard to decide which path
        to follow.<br>
        <b>6</b>&ensp;In the rapidly changing job market, # is
        a valuable skill, enabling you to adapt quickly to new
        roles and technologies.<br>
       </div>
               

            
            
          `,
        // InputRong: true,
        answer: [
          "confidence",
          "career",
          "secure",
          "alternative",
          "bewildering",
          "agility",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E2",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "B&B",
          "hotel",
          "house swap",
          "self-catering apartment",
          "villa",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What qualities do people who want to
start a blog or vlog need?
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2></textarea>
        </div>
       <div style='width:26cm'>
        Surely blogging or vlogging must be one of the easiest ways of finding fame and
        fortune? All you need is a computer and a hobby to talk about, don’t you? Well,
        although it looks simple, being a success in the blogosphere is actually
        a lot more difficult than it seems.<br>
        Kate Ross has been advising brands on how to work with bloggers and vloggers,
        and believes that if you start a blog or vlog just to earn money, it isn’t going to work.
        Kate says you need to be passionate about your topic and you need to provide your
        readers or viewers with interesting content. If you don’t, your vlog or blog isn’t going
        to get positive feedback and attract subscribers.<br>
        You also need to be aware that cash isn’t going to come rolling in overnight.
        Seventeen-year-old Rosie Bea, who has a YouTube fashion channel, says her blog only
        started attracting the attention of advertisers after she had put in months and months
        of unpaid work and built up a big fan base. Rosie was also combining her vlog work
        with her law studies, and she advises vloggers in the same position to make sure that
        they plan carefully so that they have time to do both things properly.<br>
        So, while the tabloid press often talks about the instant success of young bloggers
        and vloggers, it isn’t really instant at all. The people who are successful have done
        well because they are hard-working and passionate about what they do, and it is this
        dedication and passion that attracts subscribers and advertisers to them. The press is
        only interested in them once they are in the public eye, but they have achieved their
        celebrity status through hours and hours of hard work.<br>
       </div>
               

            
            
          `,
        // InputRong: true,
        answer: [
          "They need to be passionate about their topic, hard-working and dedicated.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E3",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#42429C'>Reading Strategy</span>. Then read the text again and
choose the correct answers (a–d).
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // TickRong: true,
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-8", "1-12", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `


           <b>1</b>&ensp;Kate Ross’s job involves explaining to companies
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>how they can employ bloggers and vloggers. <br> what bloggers and vloggers do. <br> how they can collaborate with bloggers and vloggers. <br> which bloggers and vloggers earn the most money.</div>
                 </div>
            <b>2</b>&ensp;If you want your blog or vlog to be successful, you need
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>to write about passionate topics. <br> to really want to earn money. <br> to be interested in your viewers. <br> material that will catch people’s attention.</div>
                 </div>
            <b>3</b>&ensp;If you want advertisers to notice you, you need to
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>have lots of viewers. <br> work for free. <br> be well organised. <br> talk about fashion.</div>
                 </div>
            <b>4</b>&ensp;The way the media presents the success that young bloggers and vloggers have had is
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>mistaken. <br> accurate. <br> enthusiastic. <br> positive.</div>
                 </div>
      <div style='margin-top:10px;width:26cm'>
        Surely blogging or vlogging must be one of the easiest ways of finding fame and
        fortune? All you need is a computer and a hobby to talk about, don’t you? Well,
        although it looks simple, being a success in the blogosphere is actually
        a lot more difficult than it seems.<br>
        Kate Ross has been advising brands on how to work with bloggers and vloggers,
        and believes that if you start a blog or vlog just to earn money, it isn’t going to work.
        Kate says you need to be passionate about your topic and you need to provide your
        readers or viewers with interesting content. If you don’t, your vlog or blog isn’t going
        to get positive feedback and attract subscribers.<br>
        You also need to be aware that cash isn’t going to come rolling in overnight.
        Seventeen-year-old Rosie Bea, who has a YouTube fashion channel, says her blog only
        started attracting the attention of advertisers after she had put in months and months
        of unpaid work and built up a big fan base. Rosie was also combining her vlog work
        with her law studies, and she advises vloggers in the same position to make sure that
        they plan carefully so that they have time to do both things properly.<br>
        So, while the tabloid press often talks about the instant success of young bloggers
        and vloggers, it isn’t really instant at all. The people who are successful have done
        well because they are hard-working and passionate about what they do, and it is this
        dedication and passion that attracts subscribers and advertisers to them. The press is
        only interested in them once they are in the public eye, but they have achieved their
        celebrity status through hours and hours of hard work.<br>
       </div>

  `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the task and look at the photos below. Compare the
options. Prepare the reasons for your choice under the
headings (1–4) below.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["close", "flat", "free", "hard", "near"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page45/E4/1.jpg" style='height:4cm'/><br>
        <img src="img/FriendsPlus/Page45/E4/2.jpg" style='height:12cm'/><br>
        <img src="img/FriendsPlus/Page45/E4/3.jpg" style='height:12cm'/><br>
        <b>1</b>&ensp;interest for you<br>
        <b>2</b>&ensp;location<br>
        <b>3</b>&ensp;cost<br>
        <b>4</b>&ensp;time required<br>
        <textarea id=0 rows=7></textarea>

          `,
        answer: [
          "hardly",
          "hard",
          "free",
          "freely",
          "closely",
          "close",
          "flatly",
          "flat",
          "nearly",
          "near",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "closely",
          "hardly",
          "lately",
          "lively",
          "nearly",
          "rough",
          "widely",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Now do the task using your notes from exercise 4.

`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
                <textarea id=0 rows=7></textarea>

          `,
        answer: [
          "lively",
          "nearly",
          "rough",
          "closely",
          "widely",
          "hardly",
          "lately",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the email with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
              <img src="img/FriendsPlus/Page45/E6/1.jpg" style='height:15cm'/>
              <div style='position:absolute;top:129px;left:408px'>#</div>
              <div style='position:absolute;top:164px;left:46px'>#</div>
              <div style='position:absolute;top:198px;left:437px'>#</div>
              <div style='position:absolute;top:233px;left:94px'>#</div>
              <div style='position:absolute;top:267px;left:199px'>#</div>
              <div style='position:absolute;top:336px;left:46px'>#</div>
           
        </div>

          `,
        answer: [
          "easy",
          "pamper",
          "physically",
          "adventure",
          "batteries",
          "experiences",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P45-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
