import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E1",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign:'center',

    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct verbs to complete the sentences.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: ["3-0", "2-0", "0-0", "1-4", "4-0", "5-0", "6-0"],
        initialValue: [],
      },
      Layout: `
            <div style='line-height:47px'>
                  <b>1</b>&ensp;What <input id=0 type=Circle > at weekends?<br>
                  <b>2</b>&ensp;Next time <input id=1 type=Circle > Jenny, I’ll tell her I’ve seen you.<br>
                  <b>3</b>&ensp;<input id=2 type=Circle > about space travel at school this week.<br>
                  <b>4</b>&ensp;What time <input id=3 type=Circle > tomorrow?<br>
                  <b>5</b>&ensp;<input id=4 type=Circle > in the centre of town?<br>
                  <b>6</b>&ensp;My brother <input id=5 type=Circle > my phone without asking. It’s really annoying!<br>
                  <b>7</b>&ensp;<input id=6 type=Circle > to Ted’s party on Saturday night?<br>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 250,
      paddingTop: 8,
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with will or be going to and the
verb in brackets.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
              <b>1</b>&ensp;Have you finished your tea? Pass me your cup and I #(pour) you some more.<br>
              <b>2</b>&ensp;Don’t worry, we #(phone) you as soon as we reach the campsite.<br>
              <b>3</b>&ensp;If it’s already half past six, we #(miss) the start of the film.<br>
              <b>4</b>&ensp;It doesn’t matter if you don’t have a jacket with you. I #(lend) you mine.<br>
              <b>5</b>&ensp;Max has worked hard, so I’m sure he #(do) well in the exam.<br>
              <b>6</b>&ensp;I’ve decided that next weekend I #(paint) my room a different colour.<br>
          </div>
          
          `,
        answer: [
          "will pour",
          "will phone",
          "are going to miss",
          "will lend",
          "will do",
          "am going to paint",
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      border: "2px solid",
      width: 40,
      height: 40,
      borderRadius: 5,
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match (1–6) with (a–f) to make sentences.`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
           <div style='display:flex'>
           <div style='margin-right:100px'>
            <b>1</b>&ensp;Stefan is going camping<br>
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>next week. <br>every summer.</div>
                 </div>
            <b>2</b>&ensp;Have you decided what time<br>
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>you’re going out? <br>you go out?</div>
                 </div>
            <b>3</b>&ensp;Tom is always talking<br>
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>when the TV’s on! <br>clearly.</div>
                 </div>
            
           </div>
           <div>
            <b>4</b>&ensp;Can you give this to Ben next time<br>
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                      <div>you’re seeing him? <br>you see him?</div>
                  </div>
              <b>5</b>&ensp;I always<br>
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                      <div>cycle to school. <br>am cycling to school.</div>
                  </div>
              <b>6</b>&ensp;Do you play basketball<br>
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                      <div>this Saturday? <br>every Saturday?</div>
                  </div>
           </div>
           </div>
                  
          
        `,
    },
  },
  4: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E4",
    // audio: "Audios/2-09.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the correct form of the verbs below.`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "accuse",
          "apologise",
          "beg",
          "blame",
          "insist",
          "thank",
          "want",
          "warn",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-4", "2-8", "3-4", "4-0", "5-0", "6-8", "7-4"],
        initialValue: [],
      },
      Layout: `
           
           
            <b>1</b>&ensp;‘How often running?’ ‘Twice a week, usually.’
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>are you going <br> do you go <br> will you go </div>
                 </div>
            <b>2</b>&ensp;‘Is the café open today?’ ‘Yes, but it at all on Sundays.’
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>isn’t opening <br> doesn’t open <br> won’t open</div>
                 </div>
            <b>3</b>&ensp;Don’t be late tomorrow – the bus at nine o’clock.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>will leave <br> is going to leave <br> leaves  </div>
                 </div>
            
          
            <b>4</b>&ensp;Let’s go for another swim after we this drink.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                      <div>are going to finish <br> finish <br> will finish</div>
                  </div>
              <b>5</b>&ensp;anything interesting at the moment?
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                      <div>Are you reading <br> Will you read <br> Are you going to read</div>
                  </div>
              <b>6</b>&ensp;You look cold. I you my hat to wear.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                      <div>’ll give <br> ’m giving <br> ’m going to give</div>
                  </div>
              <b>7</b>&ensp;I’m so excited! My favourite singer here in June.
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=6 type=Circle /></div>
                      <div>plays <br> will play <br> is playing</div>
                  </div>
              <b>8</b>&ensp;Be careful! That ladder isn’t safe – you  !
                  <div style='display:flex;'>
                      <div style='margin-left:20px;margin-right:10px'><input id=7 type=Circle /></div>
                      <div>are falling <br> are going to fall <br> fall</div>
                  </div>
           
            
        `,
    },
  },
  5: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E5",
    // audio: "Audios/2-10.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -70,
      marginLeft: 20,
      width: 700,
      paddingTop: 3,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Correct the underlined mistakes in the sentences. Use the
correct present or future tense`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          
         <div style='line-height:45px'>
          <b>1</b>&ensp;Una’s favourite food is Chinese, but <u>I’m preferring</u> Mexican.<br><textarea id=0 rows=2 ></textarea><br>
          <b>2</b>&ensp;The clouds in the distance are really black – <u>there will be</u> a storm.<br><textarea id=1 rows=2 ></textarea><br>
          <b>3</b>&ensp;The school term <u>is going to end</u> next Friday; I can’t wait!<br><textarea id=2 rows=2 ></textarea><br>
          <b>4</b>&ensp;Ellie <u>is practising</u> the guitar for an hour each day.<br><textarea id=3 rows=2 ></textarea><br>
          <b>5</b>&ensp;The sun’s just come out, so I think <u>I’m going</u> for a walk in the park.<br><textarea id=4 rows=2 ></textarea><br>
          <b>6</b>&ensp;When <u>you’ll find out</u> the final score, let me know.<br><textarea id=5 rows=2 ></textarea><br>
          <b>7</b>&ensp;My sister has decided that she <u>doesn’t eat</u> chocolate until the end of this month.<br><textarea id=6 rows=2 ></textarea><br>
          <b>8</b>&ensp;Trains to London <u>will leave</u> here every half an hour. <br><textarea id=7 rows=2 ></textarea><br>
        </div>
          `,
        answer: [
          `Una's favourite food is Chinese, but  I prefer Mexican.`,
          `The clouds in the distance are really black - there's going to be a storm.|The clouds in the distance are really black - there is going to be a storm.`,
          `The school term ends next Friday; I can't wait!|The school term ends next Friday; I cannot wait!`,
          `Ellie practises the guitar for an hour each day.`,
          `The sun's just come out, so I think I'll go for a walk in the park.|The sun's just come out, so I think I will go for a walk in the park.|The sun has just come out, so I think I'll go for a walk in the park.|The sun has just come out, so I think I will go for a walk in the park.`,
          `When you find out the final score, let me know.`,
          `My sister has decided that she isn't going to eat chocolate until the end of this month.|My sister has decided that she is not going to eat chocolate until the end of this month.`,
          `Trains to London leave here every half an hour. `,
        ],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 158,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the email with the correct form of the verbs in
brackets. There may be more than one possible answer.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='position:relative'>
            <img src="img/FriendsPlus/Page5/E6/1.jpg" style='height:18cm'/>
            <div style='position:absolute;top:129px;left:301px'>#</div>
            <div style='position:absolute;top:164px;left:552px'>#</div>
            <div style='position:absolute;top:199px;left:215px'>#</div>
            <div style='position:absolute;top:235px;left:48px'>#</div>
            <div style='position:absolute;top:235px;left:539px'>#</div>
            <div style='position:absolute;top:305px;left:125px'>#</div>
            <div style='position:absolute;top:340px;left:97px'>#</div>
            
          </div>
          
          
          `,
        answer: [
          "are enjoying",
          "are coming",
          "live",
          "don't see/do not see",
          "are going to stay / are staying",
          "arrive",
          "will love",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "12WB-2024-I-P5-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page5/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
