import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "adventure holiday",
          "backpacking",
          "beach holiday",
          "beach house",
          "caravan",
          "cruise",
          "guest house",
          "holiday home",
          "self-catering apartment",
          "surfing",
          "tent",
          "time-share apartment",
          "volunteering",
          "winter sports holiday",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete the sentences.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 500 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `have_saved / be_saving `,
          `be_finishing / have_finished `,
          `be_playing / have_played `,
          `be_doing / have_done `,
          `have_finished / be_finishing `,
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-0"],
        initialValue: [],
      },
      Layout: `


      <div style='width:26cm'>

    <b>1</b>&ensp;By the time I go on holiday, I’ll  <input id=0 type=Circle /> plenty of spending money.<br>
    <b>2</b>&ensp;Could you call me back in half an hour? I expect I’ll  <input id=1 type=Circle /> lunch by then.<br>
    <b>3</b>&ensp;I won’t  <input id=2 type=Circle /> in the football match this Saturday as I’ll be on my way to Spain.<br>
    <b>4</b>&ensp;What will you  <input id=3 type=Circle /> at 7 p.m. tomorrow?<br>
    <b>5</b>&ensp;We still won’t  <input id=4 type=Circle /> our exams by next Monday<br>
      </div>

     

        
        `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 350, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "B&B",
          "hotel",
          "house swap",
          "self-catering apartment",
          "villa",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Correct the mistakes in the sentences. Tick the correct
sentences.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          fontSize: 24,
          marginLeft: 24,
          width: 850,
          // textAlign: "center",
        },
        answers: [
          "",
          "By the end of May I'll have been living here for 10 years.|By the end of May I will have been living here for 10 years.",
          "It's hoped that scientists will discover a cure for cancer soon.|It is hoped that scientists will discover a cure for cancer soon.",
          "How long will you have been studying English for by the end of this year?",
          "",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["0-0", "4-0"],
        initialValue: [],
      },
      Layout: `
            <div style='line-height:50px;width:32cm'>
              <b>1</b>&ensp;Will you have cleaned your room by lunchtime?  <input id=0 color type=Circle /> <br>#<br>
              <b>2</b>&ensp;By the end of May, I’ll be living here for ten years.  <input id=1 type=Circle /> <br>#<br>
              <b>3</b>&ensp;It’s hoped that scientists will have discovered a cure for cancer soon.  <input id=2 type=Circle /> <br>#<br>
              <b>4</b>&ensp;How long will you be studying English for by the end of this year?  <input id=3 type=Circle /> <br>#<br>
              <b>5</b>&ensp;They will have released the album by the end of May.  <input id=4 type=Circle /> <br>#<br>
              
            </div>

  `,
    },
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E3",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 500,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text with the future continuous, future
perfect, or future perfect continuous form of the verbs
in brackets.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
              
                    It started life above a pizza shop in the USA, but the
                    world’s most visited travel website, TripAdvisor,
                    <sup>1</sup>#(run) for over fifteen years by the
                    end of this month. This year, millions of us
                    <sup>2</sup>#(read) the online reviews and using
                    them to help us plan our holidays. The most reviewed city
                    is London – very soon, reviewers <sup>3</sup>#(post)
                    over two million reviews and <sup>4</sup>#(give) the
                    city’s attractions a rating out of five ‘bubbles’. Astonishingly,
                    the website <sup>5</sup>#(receive) reviews for so long
                    that very soon, the total number of words published
                    <sup>6</sup>#(approach) 12,000 times the number of
                    words in the complete works of Shakespeare!
             
           
        </div>

          `,
        answer: [
          "will have been running",
          "will be reading",
          "will have posted",
          "will have given",
          "will have been receiving",
          "will be approaching / will have approached",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Write the words in the correct order to form polite questions.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
 
 
 
              <div style='width:26cm'>
                <b>1</b>&ensp;going / what / will / be / you / home / time / ?<br>#<br>
                <b>2</b>&ensp;he / party / the / inviting / who / be / will / to / ?<br>#<br>
                <b>3</b>&ensp;coming / when / of / be / out / will / hospital / she / ?<br>#<br>
                <b>4</b>&ensp;be / will / leaving / teacher / year / why / next / our / ?<br>#<br>
                <b>5</b>&ensp;seeing / you / tomorrow / be / will / Marco / ?<br>#<br>
                
              </div>
             
             
            
            
          `,
        // InputRong: true,
        answer: [
          "What time will you be going home?",
          "Who will he be inviting to the party?",
          "When will she be coming out of hospital?",
          "Why will our teacher be leaving next year?",
          "Will you be seeing Marco tomorrow?",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "breathtaking",
          "dingy",
          "hospitable",
          "remote",
          "unique",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct options (a–c) to complete the text above.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["1-0", "2-4", "3-8", "0-4", "4-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page33/E5/1.jpg" style='height:18.5cm'/>






        <div style='display:flex'>  
        <div style='margin-right:200px'>  
                    <div style='display:flex'>  
                    <b>1</b>
                       <div style='display:flex;margin-left:15px' >
                          <div><input id=0 type=Circle ></div>
                          <div style='margin-left:10px'>will you have stayed <br> will you be staying <br> will you have been staying</div>
                       </div>
                    </div>
                    <div style='display:flex'>
                      <b>2</b>
                       <div style='display:flex;margin-left:15px' >
                          <div><input id=1 type=Circle ></div>
                          <div style='margin-left:10px'>will have been welcoming <br> will be welcoming <br> will have welcomed</div>
                       </div>
                    </div>
                    <div style='display:flex'>
                      <b>3</b>
                       <div style='display:flex;margin-left:15px' >
                          <div><input id=2 type=Circle ></div>
                          <div style='margin-left:10px'>will be owning <br> will have owned <br> will have been owning</div>
                       </div>
                    </div>
          </div>
          <div>

                     <div style='display:flex'>
                      <b>4</b>
                       <div style='display:flex;margin-left:15px' >
                          <div><input id=3 type=Circle ></div>
                          <div style='margin-left:10px'>will have run <br> will be running <br> will have been running</div>
                       </div>
                    </div>
                  


                    <div style='display:flex'>
                      <b>5</b>
                       <div style='display:flex;margin-left:15px' >
                          <div><input id=4 type=Circle ></div>
                          <div style='margin-left:10px'>will be paying <br> will have been paying <br> will have paid</div>
                       </div>
                    </div>
          </div>
          </div>
          
          
          

        `,
    },
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 350,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the mini-dialogues with the correct form of the
verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <div>
              <b>1</b><br><br>
              <b>2</b><br> <br>
              <b>3</b><br> <br>
              <b>4</b><br> 
            </div>
            <div style='margin:0px 20px'>
              <b>A</b><br>
              <b>B</b><br>
              <b>A</b><br>
              <b>B</b><br>
              <b>A</b><br>
              <b>B</b><br>
              <b>A</b><br>
              <b>B</b><br>
            </div>
             <div>
                Does Frank know about the party? Can I mention it to him?<br>
                Wait until this afternoon. I’ll #(speak) to him by then.<br>
                Have you heard from Mia recently?<br>
                No, but I #(meet) her tomorrow.<br>
                Where shall we stop?<br>
                At the next town. We #(travel) for three hours by then.<br>
                Are you going dancing tonight?<br>
                Probably not. My grandparents #(visit) later. <br>
            </div>
        </div>

          `,
        answer: [
          "have spoken",
          "am meeting / will be meeting / 'm meeting / 'll be meeting",
          "will have been travelling / 'll have been travelling",
          "are visiting / 'll be visiting / will be visiting",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P33-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
