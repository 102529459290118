import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 140, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the correct form of the
verbs in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;I don’t remember #(meet) him before, although he looks familiar.<br>
          <b>2</b>&ensp;It’s very rude when people stop #(talk) to you and answer their phone.<br>
          <b>3</b>&ensp;They meant #(get) off the bus at the last stop, but they missed it.<br>
          <b>4</b>&ensp;Do you regret not #(have) a party for your birthday?<br>
          <b>5</b>&ensp;My brother graduated from university and went on #(become) a vet.<br>
          <b>6</b>&ensp;If you stopped #(think) for a moment, I’m sure you’d find a solution.<br>
          <b>7</b>&ensp;He tried not #(make) too much noise when he got home. <br>
        </div>
          
          
          `,
        answer: [
          "meeting",
          "talking",
          "to get",
          "having",
          "to become",
          "to think",
          "to make",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 150, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["drive", "go", "live", "lose", "make"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the -ing form of the words
below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

         <div style='width:25cm'>
              <b>1</b>&ensp;Sam discovered that # in a city was more expensive than in a village.<br>
              <b>2</b>&ensp;# to the gym twice a week will make a real difference to your fitness.<br>
              <b>3</b>&ensp;# the match was disappointing after the team had tried so hard.<br>
              <b>4</b>&ensp;My mother taught me that # fun of other people isn’t nice.<br>
              <b>5</b>&ensp;# has become quite expensive as the cost of fuel continues to rise.<br>
          </div>
          
          
          `,
        answer: ["living", "Going", "Losing", "making", "Driving"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // marginLeft: 20,
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Match the sentence halves. Then complete the sentences
with the -ing form or the infinitive of the verbs in brackets.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `


          <div style='width:27cm'>
            <div style='display:flex;line-height:50px'>
                <div>
                  <b>1</b>&ensp;If you heard someone<br>
                  <b>2</b>&ensp;We have to get out – can’t you hear<br>
                  <b>3</b>&ensp;It was a really strong earthquake; we felt<br>
                  <b>4</b>&ensp;We stood and watched the parade<br>
                  <b>5</b>&ensp;Did you see him<br>
                  <b>6</b>&ensp;I was horrified to feel<br>
                </div>
                <div>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                  <input width=40px height=40px border="2px solid" border-radius=5px margin-left=100px /><br>
                </div>
            </div>
            <b>a</b>&ensp;the ground #(shake) for several minutes.<br>
            <b>b</b>&ensp;#(score) that last goal? It was amazing!<br>
            <b>c</b>&ensp;something #(crawl) slowly up my leg as I was lying in bed.<br>
            <b>d</b>&ensp;#(shut) the door then he must have already gone out.<br>
            <b>e</b>&ensp;the fire alarm #(ring)?<br>
            <b>f</b>&ensp;#(come) down the street while everyone cheered. <br>
          </div>
          
          
          `,
        answer: [
          "d",
          "e",
          "a",
          "f",
          "b",
          "c",
          "shaking",
          "score",
          "crawling",
          "shut",
          "ringing",
          "coming",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct words to complete the text
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 250, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "say / not_say",
          "pretend / not_pretend",
          "wait / not_wait",
          "let / not_let",
          "cause / not_cause",
          "leave / not_leave",
          "be / not_be",
        ],
        answers: ["0-4", "1-0", "4-4", "6-4", "2-0", "3-0", "5-0"],
        initialValue: [],
      },
      Layout: `



                <div style='width:26cm'>
                  Many people would rather <sup>1</sup><input id=0 type=Circle />  anything when
                  they experience bad service. For example, in a restaurant,
                  they would sooner <sup>2</sup><input id=1 type=Circle />  that their meal
                  was fine, even if they didn’t really enjoy it. But why <sup>3</sup><input id=2 type=Circle />  until afterwards, then tell your friends about it?
                  Surely you’d sooner <sup>4</sup><input id=3 type=Circle />  those responsible know
                  that there’s a problem? For some reason, many of us think
                  we’d better <sup>5</sup><input id=4 type=Circle />  a fuss, but would you really
                  rather <sup>6</sup><input id=5 type=Circle />  a shop or restaurant feeling that
                  you’ve been cheated? Why <sup>7</sup><input id=6 type=Circle />  more assertive next
                  time you have a genuine complaint? 
                </div> 
           
      
          
            
          
           
            
        `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 658, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct answers (a–d) to complete the sentences.  
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-8", "1-12", "2-0", "3-0", "4-8", "5-4"],
        initialValue: [],
      },
      Layout: `
            <b>1</b>&ensp;If it’s still snowing, why ____ a cab home?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>not getting <br> get <br> not get <br> getting</div>
                 </div>
            <b>2</b>&ensp;I’m sure my parents would rather ____ to the theatre than a night club!
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>going <br> not going <br> not go <br> go</div>
                 </div>
            <b>3</b>&ensp;____ in the corridor isn’t allowed.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>Running <br> Not run <br> Run <br> Not running</div>
                 </div>
            <b>4</b>&ensp;She didn’t mean ____ so much salt in the curry.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>to put <br> putting <br> not to put <br> not putting</div>
                 </div>
            <b>5</b>&ensp;Did you see anyone ____ suspiciously last night?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>not behaving <br> to behave <br> behaving <br> not behave</div>
                 </div>
            <b>6</b>&ensp;Would you sooner ____ at home this evening or go into town?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                    <div>staying <br> stay <br> to stay <br> not stay</div>
                 </div>
              
        `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 700,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Rewrite the sentences so that they have a similar meaning.
Use the word in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div>
        <b>1</b>&ensp;If you don’t understand, you should ask the teacher. (why)<br>#<br>
        <b>2</b>&ensp;Would you prefer chips or potatoes? (rather)<br>#<br>
        <b>3</b>&ensp;It wasn’t her intention to upset anyone. (mean)<br>#<br>
        <b>4</b>&ensp;She forgot to post the parcel yesterday. (remember)<br>#<br>
        <b>5</b>&ensp;Don’t spend ages on the computer before bed. (better)<br>#<br>
        <b>6</b>&ensp;Alex won’t talk to him since they fell out. (stopped)<br>#<br>
       </div>
        
          `,
        answer: [
          "Why don't you ask the teacher if you don't understand?",
          "Would you rather have chips or potatoes?",
          "She did not mean to upset anyone.|She didn't mean to upset anyone.",
          "She did not remember to post the parcel yesterday.|She didn't remember to post the parcel yesterday.",
          "You'd better not spend ages on the computer before bed.|You had better not spend ages on the computer before bed.",
          "Alex has stopped talking to him since they fell out.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P19-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page19/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page19/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
