import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E1",
    // audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 40,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match (1–10) with (a–j) to make travel collocations.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "447px",
              left: "141px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "447px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [
          "1-11",
          "10-2",
          "12-3",
          "13-4",
          "17-5",
          "16-6",
          "15-7",
          "19-8",
          "18-9",
          "0-14",
        ],
        initialValue: ["0-14"],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
<input id=14 type=boxMatch />
<input id=15 type=boxMatch />
<input id=16 type=boxMatch />
<input id=17 type=boxMatch />
<input id=18 type=boxMatch />
<input id=19 type=boxMatch />
            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;get<br>
                    <b>2</b>&ensp;go<br>
                    <b>3</b>&ensp;taste<br>
                    <b>4</b>&ensp;put up<br>
                    <b>5</b>&ensp;light<br>
                    <b>6</b>&ensp;do<br>
                    <b>7</b>&ensp;have<br>
                    <b>8</b>&ensp;help<br>
                    <b>9</b>&ensp;travel<br>
                    <b>10</b>&ensp;sleep<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;a local dish<br>
                    <b>b</b>&ensp;off the beaten track<br>
                    <b>c</b>&ensp;the tent<br>
                    <b>d</b>&ensp;a campfire<br>
                    <b>e</b>&ensp;away from it all<br>
                    <b>f</b>&ensp;with your bags<br>
                    <b>g</b>&ensp;a lovely view<br>
                    <b>h</b>&ensp;some volunteer work<br>
                    <b>i</b>&ensp;rough<br>
                    <b>j</b>&ensp;light<br>
                  </div>
            </div>
          
        `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E2",
    // audio: "Audios/1.16.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 300, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the text with travel collocations from exercise 1.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='width:20cm'>
               <b>
                 If you are a traveller, not a tourist – if you like to
                  <br><sup>1</sup># rather than follow the crowds –
                  then come to Iceland for a whole new experience!

               </b>
              <div style=''>
                Iceland is wild and exciting and you can really
                forget about home and just <sup>2</sup># in this
                breathtaking environment. Stay in some of our lovely
                little hotels and you can <br><sup>3</sup># of
                ice-capped mountains from your window. Then join
                us to go whale watching, or fishing in frozen lakes.
                You can <sup>4</sup># on the lakeshore to cook
                your freshly caught fish and take a sleeping bag to
                sleep under the stars! Or you can <sup>5</sup>#
                in one of the many wonderful restaurants, if you
                prefer. Afterwards, you can relax outside in a volcanic
                hot tub!
              </div>
             
            </div>

            
            
          `,
        answer: [
          "go off the beaten track",
          "get away from it all",
          "have a lovely view",
          "light a campfire",
          "taste a local dish",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E3",
    audio: "Audios/1.22.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 800,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=1.22 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:#C40B44'>Reading Strategy</span>. Listen to four
conversations and make notes on the topics below.

`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/,]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <b>a</b>&ensp;Where is each person?
          <div style='margin-left:25px'>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
            </div>
          </div>
            <b>b</b>&ensp;What kind of relationship is it?
          <div style='margin-left:25px'>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
            </div>
          </div>
            <b>c</b>&ensp;What is the approximate time of day of the conversation?
          <div style='margin-left:25px'>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
            </div>
          </div>
            <b>d</b>&ensp;What is the outcome of the conversation?
          <div style='margin-left:25px'>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
            </div>
          </div>






            
            
          `,
        // InputRong: true,
        answer: [
          "cafe, office",
          "shop",
          "at home",
          "hotel / holiday apartment ",
          "boyfriend - girlfriend / wife - husband",
          "friends / sisters",
          "mother and son",
          "brothers ",
          "evening",
          "lunch time / late morning,",
          "early evening / dinner time",
          "early afternoon ",
          "They are going to eat before going to the cinema.",
          "They are going for an early lunch.",
          "The rucksack is in the attic / the boy can go camping.",
          "The boys will have another ice cream before leaving for the airport.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E4",
    // audio: "Audios/1.18.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Add <i>just</i> in the correct place in the sentences.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 350, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
            <b>1</b>&ensp;I have a short time.<input width=522px /><br>
            <b>2</b>&ensp;Could you close the door?<input width=447px /><br>
            <b>3</b>&ensp;I got to the train in time.<input width=466px /><br>
            <b>4</b>&ensp;It’s amazing!<input width=597px /><br>
            <b>5</b>&ensp;He has left.<input width=609px /><br>
            <b>6</b>&ensp;We have to buy milk now.<input width=449px /><br>
            <b>7</b>&ensp;This film’s so funny!<input width=517px /><br>
            <b>8</b>&ensp;Could you repeat your name? <input width=400px /><br>
        </div>

          `,
        answer: [
          "I just have a short time. ",
          "Could you just close the door?",
          "I got to the train just in time.",
          "It's just amazing!",
          "He has just left.",
          "We just have to buy milk now.",
          "This film's just so funny!",
          "Could you just repeat your name?",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["able", "might", "mustn’t", "needn’t", "should (x2)", "supposed"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Match the purpose of using just (a–d) with sentences (1–8)
in exercise 4.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: ",",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

      <div style='display:flex;line-height:47px'>
      <div style='margin-right:100px'>
            <b>1</b>&ensp;I have a short time.<br>
            <b>2</b>&ensp;Could you close the door?<br>
            <b>3</b>&ensp;I got to the train in time.<br>
            <b>4</b>&ensp;It’s amazing!<br>
            <b>5</b>&ensp;He has left.<br>
            <b>6</b>&ensp;We have to buy milk now.<br>
            <b>7</b>&ensp;This film’s so funny!<br>
            <b>8</b>&ensp;Could you repeat your name? <br>
        </div>
        <div>
        <b>a</b>&ensp;for emphasis # <br>
        <b>b</b>&ensp;to mean only # <br>
        <b>c</b>&ensp;for politeness # <br>
        <b>d</b>&ensp;to mean close to the time # <br>
        </div>
        </div>

          `,
        answer: ["4, 7", "1, 6", "2, 8", "3, 5"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E6",
    audio: "Audios/1.23.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=1.23 color=white colorimg='#d1b4d8'></headphone> Listen to the conversation. Are the sentences true
(T) or false (F)?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;The conversation is happening in a restaurant. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;John reacts to both of Ella’s suggestions with decisiveness. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;Ella would prefer to spend the day sightseeing. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;John is worried the bus might be too hot. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;Ella’s attitude is that you should make the most of a trip away <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: ["F", "F", "T", "T", "T"],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P34-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page34/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
