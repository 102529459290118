import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E1",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Rewrite the sentences in more formal language. Use the
correct form of the words in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <b>1</b>&ensp;I got the booking information from you. (receive)<br>#<br>
              <b>2</b>&ensp;Your company didn’t say sorry. (offer / no apology)<br>#<br>
              <b>3</b>&ensp;I really think you are to blame. (feel strongly / at fault)<br>#<br>
              <b>4</b>&ensp;I think your online system should be looked at. (believe / review)<br>#<br>
              <b>5</b>&ensp;I don’t want other people to have the same problems that I had. (be inconvenienced / I was)<br>#<br>
              <b>6</b>&ensp;Further problems will happen. (arise)<br>#<br>
            
          

        
        
          
          `,
        // InputRong: true,
        answer: [
          "I received the booking information from you.",
          "Your company offered no apology.",
          "I feel strongly that you are at fault.",
          "I believe your online system should be reviewed.",
          "I don't want other people to be inconvenienced as I was.|I do not want other people to be inconvenienced as I was.",
          "Further problems will arise.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 900, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["far", "now", "something", "subject"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: ` Read the task and the letter below. What three things
does the writer suggest?`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Answer:</b><br>
          <div style='display:flex'><b>1</b><textarea id=0 rows=2></textarea></div>
          <div style='display:flex'><b>2</b><textarea id=1 rows=2></textarea></div>
          <div style='display:flex'><b>3</b><textarea id=2 rows=2></textarea></div>
          <img src="img/FriendsPlus/Page39/E2/1.jpg" style='height:30cm'/>
         
          

        
        
          
          `,
        // InputRong: true,
        answer: [
          "That your online booking system be reviewed.|That there should be more information and help for passengers when problems occur.|That there be bottled water available in extremely hot conditions.",
          "That your online booking system be reviewed.|That there should be more information and help for passengers when problems occur.|That there be bottled water available in extremely hot conditions.",
          "That your online booking system be reviewed.|That there should be more information and help for passengers when problems occur.|That there be bottled water available in extremely hot conditions.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E3",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // margin: "0px 10px",
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Find two examples of the subjunctive form in the letter. 
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Answer:</b><br>
          <div style='display:flex'><b>1</b><textarea id=0 rows=2></textarea></div>
          <div style='display:flex'><b>2</b><textarea id=1 rows=2></textarea></div>
          <img src="img/FriendsPlus/Page39/E3/1.jpg" style='height:15cm'/><br>
          <img src="img/FriendsPlus/Page39/E3/2.jpg" style='height:25cm'/>

       
        
          
          `,
        // InputRong: true,
        answer: [
          "I suggest that your online booking system be reviewed.|I request that there be bottled water available in extremely hot conditions.",
          "I suggest that your online booking system be reviewed.|I request that there be bottled water available in extremely hot conditions.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E4",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the requests and demands using the subjunctive
and the words in brackets.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <b>1</b>&ensp;I think you should review the booking procedure. (suggest)<br>#<br>
          <b>2</b>&ensp;I think you should announce delays. (recommend)<br>#<br>
          <b>3</b>&ensp;I think you should delay your decision. (propose)<br>#<br>
          <b>4</b>&ensp;I want my ticket to be refunded. (demand)<br>#<br>
        </div>
          `,
        answer: [
          "I suggest that you review the booking procedure.",
          "I recommend that you announce delays.",
          "I propose you delay your decision.|I propose that you delay your decision.",
          "I demand that my ticket be refunded.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 50, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "15cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "itinerary missed out an important place",
          "tour didn’t run on time",
          "tour guide was unenthusiastic / too quiet ",
          "no toilet on the bus",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the task above and make notes about what you will
complain about. Use the ideas below or your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page39/E5/1.jpg" style='height:6cm'/>
        
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E7answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page39/E7/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:113px;left:78px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:159px;left:78px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:204px;left:78px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  6: {
    unit: "Unit 4",
    id: "12WB-2024-U4-P39-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Write your letter of complaint.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <textarea id=0 rows=7></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
