import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 200,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "adrenalin",
          "burn",
          "burst",
          "convert",
          "energy",
          "high-intensity",
          "pump",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the correct form of the
words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        
        <div style='width:27cm'>
           <b>1</b>&ensp;When you are exercising, the body # fat
          and sugar into # so that you can keep
          going.<br>
          <b>2</b>&ensp;Running fast releases #, which helps
          you to # fat.<br>
          <b>3</b>&ensp;The most effective way to get fit is by doing short #
          of # exercise.<br>
          <b>4</b>&ensp;If you exercise hard, you can feel your heart #.<br>
        </div>
          `,
        answer: [
          "converts",
          "energy",
          "adrenalin",
          "burn",
          "bursts",
          "high-intensity",
          "pumping",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 150, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "attend",
          "be",
          "beat",
          "do",
          "go",
          "keep",
          "lift",
          "pedal",
          "push",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the
verbs below.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `     
        <hintbox id=0></hintbox>
              <div style='width:26cm'>
                <b>1</b>&ensp;I play to win – I # quite competitive and I’m never happier than when I’ve # an opponent.<br>
                <b>2</b>&ensp;I never # running alone after dark.<br>
                <b>3</b>&ensp;I enjoy # fit. It makes me feel healthy.<br>
                <b>4</b>&ensp;I train hard and always # myself to the limit.<br>
                <b>5</b>&ensp;My dad regularly # weights in the gym, but he hurt his back last week.<br>
                <b>6</b>&ensp;My mum # a lot of fitness classes at the local gym, but she likes # aerobics most of all.<br>
                <b>7</b>&ensp;My brother # on an exercise bike in his bedroom. He says it’s safer than going outside!<br>
              </div>
              

            
            
          `,
        answer: [
          "am",
          "beaten",
          "go",
          "keeping",
          "push",
          "lifts",
          "attends",
          "doing",
          "pedals",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E3",
    audio: "Audios/2.03.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      marginLeft: 20,
      marginBottom: -20,
      width: 700,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "awful",
          "delighted",
          "ecstatic",
          "exhausted",
          "fascinated",
          "hilarious",
          "miserable",
          "starving",
          "terrible",
          "terrified",
          "thrilled",
          "wonderful",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone color=white name=2.03 colorimg='#d1b4d8'></headphone> Read the <span style='color:#C40B44'>Listening Strategy</span>. Then read the
sentences and listen to five speakers. What words did the
speakers actually use to express these things?
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:27cm;line-height:50px'>
            <b>1</b>&ensp;Speaker 1 dislikes exercising.<textarea id=0 rows=2 ></textarea>
            <b>2</b>&ensp;Speaker 2 wonders why his chosen sport is unpopular with his peers.<textarea id=1 rows=2 ></textarea>
            <b>3</b>&ensp;Speaker 3 talks about the body’s preferred form of exercise.<textarea id=2 rows=2 ></textarea>
            <b>4</b>&ensp;Speaker 4 describes her family’s favourite pastime.<textarea id=3 rows=2 ></textarea>
            <b>5</b>&ensp;Speaker 5 explains how what we eat is converted into fuel for the body. <textarea id=4 rows=2 ></textarea>
        </div>

          `,
        answer: [
          "I just can't stand sport or going to the gym.|I just can not stand sport or going to the gym.",
          "I really don't understand why more people my age don't play it.|I really do not understand why more people my age don't play it.|I really do not understand why more people my age do not play it.|I really don't understand why more people my age do not play it.",
          "in short bursts of fast activity",
          "We have a trampoline in our garden and we all love bouncing on it.",
          "a type of sugar called glucose, which goes directly into our blood for energy.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E4",
    audio: "Audios/2.04.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone color=white name=2.04 colorimg='#d1b4d8'></headphone> Listen to three texts where people talk about
their preferred form of exercising. Choose the correct
answers (a–c).
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 900, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c", "a <br> b <br> c", "a <br> b <br> c"],
        answers: ["0-8", "1-4", "2-8"],
        initialValue: [],
      },
      Layout: `






  <b>Text 1</b>            <br>
<b>1</b>&ensp;These speakers explain
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>how their favourite activity doesn’t involve teamwork. <br> that they don’t like the gym or sports. <br> what it takes to succeed in their favourite activity.</div>
                 </div>
              <b>Text 2</b><br>
                 <b>2</b>&ensp;This speaker’s intention is to
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>detail his sport’s training regime. <br> explain why the sport’s rules are important. <br> warn that his sport is very dangerous.</div>
                 </div>
              <b>Text 3</b><br>
                 <b>3</b>&ensp;This speaker is explaining
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>the effects of exercise on the various muscle groups. <br> how exercise also affects the mind. <br> that exercise must be regular to be beneficial.</div>
                 </div>

  `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      marginLeft: 10,
      width: 900,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 800,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["date", "enjoy", "find", "not imagine", "live", "star", "think"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Look at the task and the photos below. Then read the
questions (1–4) and write notes.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
                <b>1</b>&ensp;What is the common theme of the photos? <textarea id=0 rows=2></textarea>
                <b>2</b>&ensp;What can you see in both photos? <textarea id=1 rows=2></textarea>
                <b>3</b>&ensp;What is the main difference between the photos? <textarea id=2 rows=2></textarea>
                <b>4</b>&ensp;Do you think celebrities should spend more time with their fans? Why? / Why not? <textarea id=3 rows=2></textarea>
           
        </div>

        <img src="img/FriendsPlus/Page50/E1/1.jpg" style='height:18cm'/>
        
            
            
          `,

        answer: [
          "The common theme is celebrities and their fans.",
          "There are celebrities and lots of fans in both photos. Some of the fans have pens and paper for autographs. Both celebrities have sunglasses.",
          "In photo B there are lots of phones and a girl is taking a selfie. The celebrity is a man, whereas in photo A it's a woman.",
          "",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `

         
            
            
          `,
        answer: [
          "hadn't played tennis",
          "had eaten her dinne",
          "had been saving for ages",
          "because he had broken his arm.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P50-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page50/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
