import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E1",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task above. Identify the elements that need to
be included in the article. How many paragraphs do you
think it should have? Read the article and check.

`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       
              <img src="img/FriendsPlus/Page55/E1/1.jpg" style='height:6cm'/><br>
              <b>Answer:</b><br>
                <div style='display:flex'><b>1</b>&ensp;<textarea id=0 rows=2></textarea></div>
                <div style='display:flex'><b>2</b>&ensp;<textarea id=1 rows=2></textarea></div>
              <img src="img/FriendsPlus/Page55/E1/2.jpg" style='height:30cm'/>
          `,
        answer: [
          "benefits of PE, teenagers taking regular exercise during exam time",
          "how students could incorporate more regular exercise into their lives It should have 4 paragraphs.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 600, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "as soon as possible",
          "I can’t wait to hear",
          "give",
          "I think",
          "I want to",
          "needs",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Find six comment adverbs in the article. `,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2></textarea>
        </div>
          
          <img src="img/FriendsPlus/Page55/E1/2.jpg" style='margin-top:10px;height:30cm'/>
         
          

        
        
          
          `,
        // InputRong: true,
        answer: [
          "Obviously, Consequently, Naturally, Personally, Surely, Interestingly",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E3",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // margin: "0px 10px",
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2385C6'>Writing Strategy</span>. Find and underline six
different conjunctions in the article (excluding <i>and, as</i>
and <i>when</i>).
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          // border: "2px solid",
          color: "black",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 10px",
          // borderRadius: "50%",
          textDecoration: "underline",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 20,
        listWords: [
          `It is said that due to academic pressures, many older students
in Britain do not have the time in their school schedules for
more than two hours of PE a week. Surely this is not enough
for young people to maintain healthy lifestyles?`,
          `Obviously, students see studying for exams as their
overriding priority, as it is vital for good grades and future
careers. However, it is simply not healthy for students to
spend all their time at their desks. Interestingly, research has
shown that regular exercise boosts concentration, reduces
stress and aids restful sleep. Consequently, it would appear
that exercise is highly important when it comes to successful
studying. Therefore, if the school is unable to include enough
exercise in students’ school timetables, the students should
take responsibility for it themselves.`,
          `Naturally, I think there are other ways for students to ensure
they get enough exercise, for_example by walking or cycling
to school. Moreover, after-school clubs offer various types
of exercise. So too do local leisure centres. It is merely a
question of having the will power to organise exercise and
stick to it. Once students feel the benefit of regular exercise,
it is easier for them to maintain it.`,
          `Personally, I think it is extremely important that young people
realise that continuing exercise during periods of intense
study can help them achieve higher grades. So, let’s make a 
move today!`,
        ],
        answers: ["1-132", "1-40", "2-30", "2-44", "3-50", "2-106"],
        initialValue: [],
      },
      Layout: `


      <img src="img/FriendsPlus/Page55/E3/1.jpg" style='height:5.5cm'/>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page55/E3/2.jpg" style='height:30cm'/>
        <div style='position:absolute;top:129px;left:28px;font-size:25px;line-height:35px;width:185mm'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:317px;left:28px;font-size:25px;line-height:35px;width:185mm'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:680px;left:28px;font-size:25px;line-height:35px;width:185mm'><input id=2 type=Circle /></div>
        <div style='position:absolute;top:940px;left:28px;font-size:25px;line-height:35px;width:185mm'><input id=3 type=Circle /></div>
        
      </div>

     

        
        `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E4",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      marginBottom: -20,
      marginLeft: 20,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Link the sentences using the words below.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["although", "moreover", "since", "so", "therefore", "whereas"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <b>1</b>&ensp;He doesn’t like exercising much. He still does it.<textarea id=0 rows=2></textarea>
        <b>2</b>&ensp;Exercise increases your muscle strength. It improves your brain power.<textarea id=1 rows=2></textarea>
        <b>3</b>&ensp;He has a lot of fizzy drinks. His teeth aren’t good.<textarea id=2 rows=2></textarea>
        <b>4</b>&ensp;Sweets are unhealthy. Fruit is good for you.<textarea id=3 rows=2></textarea>
        <b>5</b>&ensp;We’ve been exercising. We’ve lost weight.<textarea id=4 rows=2></textarea>
          `,
        answer: [
          "Although he doesn't like exercising much, he still does it. / He doesn't like exercising much, although he still does it.",
          "Exercise increases your muscle strength. Moreover it improves your brain power.",
          "He has a lot of fizzy drinks, so his teeth aren't good. / He has a lot of fizzy drinks, therefore his teeth aren't good. / He has a lot of fizzy drinks, so / therefore his teeth aren't good.",
          "Sweets are unhealthy, whereas fruit is good for you.",
          "Since we've been exercising, we've lost weight. / We've lost weight since we've been exercising.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 730,
      paddingTop: 1,
    },
    stylesTextInput: { width: 50, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "15cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "itinerary missed out an important place",
          "tour didn’t run on time",
          "tour guide was unenthusiastic / too quiet ",
          "no toilet on the bus",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the task above and make notes on your ideas for
each paragraph. Choose a title. Plan your article.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page55/E5/1.jpg" style='height:6cm'/>
        <textarea id=0 rows=10></textarea>
        
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E7answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page55/E7/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:109px;left:68px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:155px;left:68px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:200px;left:68px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P55-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page55/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Write your article. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=10></textarea>
        
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
};

export default json;
