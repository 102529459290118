import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["be", "not have", "not use", "not want"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct options (a–c) to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-8", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `


      <img src="img/FriendsPlus/Page49/E1/1.jpg" style='height:22cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>were used <br> are used <br> had been used</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>have been given <br> are being given <br> will be given</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>were being warned <br> are warned <br> are being warned</div>
                            </div>
                          </div>
       
                          </div>
                          <div>
                          
           <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>will be taken <br> is taken <br> has been taken</div>
                            </div>
          </div>
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>were killed <br> had been killed <br> have been killed</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>are destroyed <br> aren’t <br> weren’t destroyed destroyed</div>
             </div>
          </div>
          
          </div>
          </div>
        `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E2",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 300, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct passive form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='width:26cm'>
          <b>1</b>&ensp;Antibiotics #(prescribe) by doctors to fight disease for many years.<br>
          <b>2</b>&ensp;What would our lives be like if X-ray machines #(not invent)?<br>
          <b>3</b>&ensp;Scientists believe that a vaccine to prevent malaria #(develop) soon.<br>
          <b>4</b>&ensp;In the past, many patients #(infect) each year in dirty hospitals.<br>
          <b>5</b>&ensp;Natural plant remedies for illnesses #(discover) all the time.<br>

      </div>
            
            
          `,
        answer: [
          "have been prescribed",
          "had not been invented|hadn't been invented",
          "will be developed",
          "were infected",
          "are being discovered",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E3",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 700,
      marginLeft: 20,
      marginBottom: -20,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 250,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      marginLeft: 20,
      // fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Each student was given a book."],
        styleElement: { color: "#3D899D", marginRight: 30 },
      },
      {
        src: ["A book was given to each student."],
        styleElement: { color: "#3D899D", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite each sentence in two ways, using the underlined
words as the subjects and leaving out the agent.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:26cm'>
          <b>1</b>&ensp;The teacher gave <u>each student</u> <u>a book</u>.
          <hintbox id=0></hintbox>
          <hintbox id=1></hintbox>
          <b>2</b>&ensp;They have offered <u>both of us</u> <u>a place at university</u>. <textarea id=0 rows=2></textarea>
          <b>3</b>&ensp;Mum cooked <u>me</u> <u>a special dinner</u> last night. <textarea id=1 rows=2></textarea>
          <b>4</b>&ensp;She’s lending <u>the money</u> to <u>Anna</u>. <textarea id=2 rows=2></textarea>
          <b>5</b>&ensp;My best friend has just sent <u>me</u> <u>a text message</u>. <textarea id=3 rows=2></textarea>
          <b>6</b>&ensp;Do you think they’ll give <u>him</u> <u>the job</u>? <textarea id=4 rows=2></textarea>

      </div>
            
            
          `,
        answer: [
          "Both of us have been offered a place at university. / A place at university has been offered to both of us.",
          "I was cooked a special dinner last night. / A special dinner was cooked for me last night.",
          "The money is being lent to Anna. / Anna is being lent the money.",
          "I've just been sent a text message. / A text message has just been sent to me. / I have just been sent a text message.",
          "Do you think he will be given the job? / Do you think he'll be given the job? / Do you think the job will be given to him?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the text with the correct passive form of the
verbs below.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 400,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["bite", "cause", "give", "improve", "know", "raise"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            Louis Pasteur <sup>1</sup># as the scientist who
              proved ‘germ theory’ after he discovered that many
              illnesses <sup>2</sup># by tiny organisms. He <sup>3</sup># in France, and it was there that
              farmers asked the scientist to investigate why their wine
              turned sour; Pasteur discovered that heating the wine
              destroyed the harmful bacteria which made the wine go
              off. He later focused on human medicine. A woman took
              her son to Pasteur because the boy <sup>4</sup>#
              by a dog suffering from rabies; Pasteur cured him in the
              first-known attempt at vaccination, a process in which
              a person <sup>5</sup># a small amount of a dead or
              weak germ, so that they produce antibodies that fight
              the disease. Medicine <sup>6</sup># hugely thanks
              to Pasteur, as vaccination prevents millions of deaths
              every year.
        </div>
        
            
            
          `,
        // InputRong: true,
        answer: [
          "is known",
          "are caused / were caused",
          "was raised",
          "had been bitten",
          "is given",
          "was improved / has been improved",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E5",
    // audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["alone", "asleep", "aware", "content", "sure"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the second sentence using the correct passive
tense so that it means the same as the first.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
       <div style='display:flex'> 
            <div><b>1</b></div> 
              <div style='margin-left:15px;width:25cm'>
                I definitely didn’t break the laptop.<br>
                The laptop <input width=755px>.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>2</b> </div>
              <div style='margin-left:15px;width:25cm'>
                Has someone invited Jan to the cinema?<br>
                Has <input width=500px> to the cinema?
              </div>
          </div>
          <div style='display:flex'>
              <div><b>3</b></div> 
              <div style='margin-left:15px;width:25cm'>
                They hadn’t told us about the school trip.<br>
                We <input width=500px> the school trip.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>4</b></div> 
              <div style='margin-left:15px;width:25cm'>
                Our teacher will explain the answers next week.<br>
                The answers <input width=500px> our teacher next week.
              </div>
          </div>
          <div style='display:flex'>
              <div><b>5</b></div> 
              <div style='margin-left:15px;width:25cm'>
                They were showing students around the museum.<br>
                Students <input width=500px> around the museum.
              </div>
          </div>
         
            
            
          `,
        answer: [
          "definitely wasn't broken by me / was definitely not broken by me.",
          "Jan been invited",
          "hadn't been told about / had not been told about",
          "will be explained to us by / will be explained by",
          "were being shown",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 850,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      marginLeft: 20,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Correct the underlined mistakes in the sentences. Only
use the past simple when it is the only correct answer.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style=''> 
          <b>1</b>&ensp;Most of us <u>would believe</u> in Santa Claus when we were younger.<br>#<br>
          <b>2</b>&ensp;People <u>wouldn’t be</u> so obsessed with celebrities before the internet.<br>#<br>
          <b>3</b>&ensp;<u>Would you use</u> to enjoy eating vegetables as a child?<br>#<br>
          <b>4</b>&ensp;He <u>would study</u> engineering for two years before he became an actor.<br>#<br>
          <b>5</b>&ensp;<u>Would</u> famous actors use to earn so much money in the past?<br>#<br>
          </div>
         
            
            
          `,
        answer: [
          "Most of us used to believe in Santa Claus when we were younger.",
          "People didn't use to be so obsessed with celebrities before the internet.",
          "Did you use to enjoy eating vegetables as a child?",
          "He studied engineering for two years before he became an actor.",
          "Did famous actors use to earn so much money in the past? ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P49-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page49/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
