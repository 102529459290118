import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 25,
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Write the words in the correct order to make sentences.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div>
                  <b>1</b>&ensp;a / to / have / take / airport / We’ll / taxi / us / the <br>#<br>
                  <b>2</b>&ensp;teacher / The / again / us / our / test / made / do <br>#<br>
                  <b>3</b>&ensp;you / Would / later / prefer / call / to / me / back / ?<br>#<br>
                  <b>4</b>&ensp;stop / war / was / The / to / president / made / the <br>#<br>
                  <b>5</b>&ensp;someone / bicycle / your / Did / fix / get / you / to / ?<br>#<br>
                  <b>6</b>&ensp;check / cut / You’d / doctor / have / better / a / that <br>#<br>
              </div>
          `,
        answer: [
          "We'll have a taxi take us to the airport.",
          "The teacher made us do our test again.",
          "Would you prefer me to call back later / to call me back later?",
          "The president was made to stop the war.",
          "Did you get someone to fix your bicycle?",
          "You'd better have a doctor check that cut.",
        ],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E2answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct words to complete the sentences.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "looked / looking",
          "hidden / hiding",
          "guessing / guessed",
          "burning / burned",
          "painted / painting",
          "throwing / thrown",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `



            <div style='line-height:50px'>
                <b>1</b>&ensp;We’re having our dog  <input id=0 type=Circle /> after by a neighbour.<br>
                <b>2</b>&ensp;I’ve left the keys  <input id=1 type=Circle /> under the mat.<br>
                <b>3</b>&ensp;The film will keep you  <input id=2 type=Circle /> to the end!<br>
                <b>4</b>&ensp;Don’t leave that candle  <input id=3 type=Circle /> when you go to bed.<br>
                <b>5</b>&ensp;They got their house  <input id=4 type=Circle /> by a friend.<br>
                <b>6</b>&ensp;I don’t want that painting  <input id=5 type=Circle /> away<br>
            </div>
           
              
        `,
    },
  },
  3: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 25,
      width: 300,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct form of the verbs
in brackets.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div>
                  <b>1</b>&ensp;I’d prefer you #(tell) me the complete truth.<br>
                  <b>2</b>&ensp;I found this bottle #(float) in the sea.<br>
                  <b>3</b>&ensp;My parents can’t get anyone #(buy) our flat.<br>
                  <b>4</b>&ensp;I had the garage #(service) my car.<br>
                  <b>5</b>&ensp;Would you like your meal #(serve) with chips or salad?<br>
                  <b>6</b>&ensp;Tony was made #(apologise).<br>
              </div>
          `,
        answer: [
          "to tell",
          "floating",
          "to buy",
          "service",
          "served / to be served",
          "to apologise",
        ],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct answers (a–c).
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: [
          "0-8",
          "1-4",
          "2-8",
          "3-4",
          "4-0",
          "5-8",
          "6-0",
          "7-8",
          "8-0",
          "9-4",
        ],
        initialValue: [],
      },
      Layout: `
<img src="img/FriendsPlus/Page79/E4/1.jpg" style='height:24.5cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>play <br> playing <br> to play</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>bringing up <br> making up <br> turning up</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>let on <br> owned up <br> passed off</div>
                            </div>
                          </div>
                          <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>call <br> calling <br> to call</div>
                            </div>
                             </div>
                             <div style='display:flex'>
                              <b>5</b>
                              <div style='display:flex;margin-left:15px' >
                                  <div><input id=4 type=Circle ></div>
                                  <div style='margin-left:10px'>played on <br> set up <br> zoomed in</div>
                              </div>
                            </div>
                          </div>
        <div>
                          
          
          
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>got over <br> put down <br> taken in</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>gave a thumbs up <br> pursed their lips <br> raised their eyebrows</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>8</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=7 type=Circle ></div>
                <div style='margin-left:10px'>asking out <br> going out <br> making out</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>9</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=8 type=Circle ></div>
                <div style='margin-left:10px'>of <br> for <br> by</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>10</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=9 type=Circle ></div>
                <div style='margin-left:10px'>does it cost <br> it cost <br> did it cost</div>
             </div>
          </div>
         
          </div>
          </div>
        `,
    },
  },
  5: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 800,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `You have read an article about the dangers of using social
media. Write an article for your school website about the
risks associated with using social media and propose ways
of persuading young people to use it responsibly
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
            <textarea id=0 rows= 8></textarea>
          `,
        answer: [
          "silly argument forgotten",
          "professional take their photos",
          "your bag stolen",
          "made to prove his age|made to prove his age by the police",
          "to meet tomorrow evening",
          "the man to understand us",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box. Then complete the sentences
with your own ideas. Use verb patterns from this page.
Circle the verb patterns in your sentences.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `

        <div>
          <b>1</b>&ensp;I hate being made <input width=500px />.
          <b>2</b>&ensp;I plan to get my <input width=500px />.
          <b>3</b>&ensp;I’d prefer my parents <input width=500px />.
          <b>4</b>&ensp;I would never leave my <input width=500px />.
          <b>5</b>&ensp;I sometimes have my <input width=500px />.
          <b>6</b>&ensp;I once found my <input width=500px />.
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Cumulative Review 4",
    id: "12WB-2024-CR4-P79-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
