import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct answers (a–c) to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "✓ <br> ✓ <br> ✓",
          "",
          "",
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-4", "5-8", "6-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page12/E1/1.jpg" style='height:21cm'/>
      <div style='display:flex'>  
        <div style='margin-right:200px'>  
        <div style='display:flex'>  
          <b>1</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=0 type=Circle ></div>
                <div style='margin-left:10px'>put her through <br> let her down <br> turned her down</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=1 type=Circle ></div>
                <div style='margin-left:10px'>calls off <br> puts down <br> gets over</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=2 type=Circle ></div>
                <div style='margin-left:10px'>puts him off <br> puts him through <br> talks him into</div>
             </div>
          </div>
           <div style='display:flex'>
            <b>4</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=3 type=Circle ></div>
                <div style='margin-left:10px'>go out with <br> put up with <br> split up with</div>
             </div>
          </div>
        </div>
        <div>
         
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>turn up <br> get on <br> look after</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>make Toby up <br> mess Toby about <br> ask Toby out</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>turn her down <br> put her down <br> put her through</div>
             </div>
          </div>
          
          </div>
          </div>
          <div>
           

        `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 210, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the verbs
below`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
          <div style='width:27cm'>
            <b>1</b>&ensp;I can’t believe Ken asked me out on a date and then #!<br>
            <b>2</b>&ensp;Rob had a huge row with Sam last week and they still #.<br>
            <b>3</b>&ensp;Surprisingly, he had no trouble # hisgirlfriend when they split up.<br>
            <b>4</b>&ensp;I’m so angry. You’ve # me # somuch recently.<br>
            <b>5</b>&ensp;I’ve made my mind up, so don’t try # me # going!<br>
          </div>
          
          
          `,
        answer: [
          "didn't turn up|did not turn up",
          "haven't made up|have not made up",
          "getting over",
          "put",
          "through",
          "talking",
          "into",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E3",
    // audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text with the correct particles.`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:20cm;background:rgb(230,217,234);padding:10px 30px;border-radius:15px;border:5px solid white;box-shadow:10px 4px 5px 6px gray'>
          After my best friend broke <sup>1</sup># with her last
          boyfriend, Sam, she didn’t go <sup>2</sup># with anyone
          for a few months. Sam had been messing her
          <sup>3</sup># for ages and she couldn’t put <sup>4</sup># with it
          any longer, so in the end, she decided to split
          <sup>5</sup># with him. But now she’s met someone new.
          He’s picking her <sup>6</sup># from school later today, so
          I’ll finally get to meet him!
       </div>
          
          
          `,
        answer: ["up", "out", "about", "up", "up", "up"],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box and the dictionary entries.
Are the phrasal verbs separable or inseparable? Put them
in the correct column in the table. Then add the phrasal
verbs below.
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page12/E4/1.jpg" style='height:7cm'/>
      <table style="width: 80%; border-collapse: collapse;">
        <tr>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Separable</td>
            <td style="background: rgb(178 121 151);color:white;border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;">Inseparable</td>
        </tr>
        <tr>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=0 rows=2 ></textarea> </td>
            <td style="border: 2px solid rgb(152 5 154); padding: 5px 10px; text-align: center;"> <textarea id=1 rows=2 ></textarea> </td>
        </tr>
    </table>
          `,
        answer: [
          "bring up, call off, let down, turn down",
          "get over, look after, run into, take after",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write sentences to illustrate the meaning of the phrasal
verbs in the table in exercise 4.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <div style='width:25cm'>
            <b>1</b>&ensp;#(call off)<br>
            <b>2</b>&ensp;#(let down)<br>
            <b>3</b>&ensp;#(look after)<br>
            <b>4</b>&ensp;#(run into)<br>
            <b>5</b>&ensp;#(take after)<br>
            <b>6</b>&ensp;#(turn down)<br>
         
          
        </div>
          `,
        answer: ["few", "one", "a", "none", "both", "no"],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            In <sup>1</sup># recent survey, two thousand British
            men and women were asked about their friendships.
            The women turned out to be twice as good at making
            friends for life, with one in three saying that their best
            friend was someone they had met at school. In contrast,
            only one in six men were best friends with <sup>2</sup>#
            school friend. Although the men had between fifty
            and a hundred Facebook friends and fifty friends’
            numbers in their phones, they only had a <sup>3</sup>#
            close friends – on average, about five. <sup>4</sup>#
            sexes admitted to having at least one person in their
            friendship group they couldn’t stand; although not
            quite as <sup>5</sup># men as women confessed to this, it
            was still a surprising 13%! <sup>6</sup># survey concluded
            that all <sup>7</sup># us can expect to have fewer close
            friends as we get older, with only about three.
            
          </div>
          
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P12-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page12/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
