import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the gestures below with the pictures (1–6).
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'>
            <div style='text-align:center'>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/1.jpg" style='height:5cm'/><br>
                  <b>1</b>#
                </div>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/4.jpg" style='height:5cm'/><br>
                  <b>4</b>#
                </div>
            </div>
            <div style='text-align:center;margin:0px 50px'>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/2.jpg" style='height:5cm'/><br>
                  <b>2</b>#
                </div>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/5.jpg" style='height:5cm'/><br>
                  <b>5</b>#
                </div>
            </div>
            <div style='text-align:center'>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/3.jpg" style='height:5cm'/><br>
                  <b>3</b>#
                </div>
                <div>
                  <img src="img/FriendsPlus/Page24/E1/6.jpg" style='height:5cm'/><br>
                  <b>6</b>#
                </div>
            </div>
        </div>

        
            
            
          `,
        answer: [
          "shrug your shoulders",
          "scratch your head",
          "cover your mouth",
          "give a thumbs up",
          "cross your legs",
          "point",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 200, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the text with the correct form of the words below.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
       <div style='width:20cm'>
        Mark was really nervous before the Spanish speaking
        exam! He either fidgeted and played with a pen or he
        <sup>1</sup># his fingernails. He also started
        <sup>2</sup># , even though he wasn’t sleepy! When
        he looked at me, he <sup>3</sup># nervously, but there
        was nothing funny about the situation. Then, in the exam,
        instead of answering the examiner’s questions with a ‘yes’
        or ‘no’ and an explanation, he just <sup>4</sup># or
        <sup>5</sup># his head! Luckily, the examiner
        <sup>6</sup># at these answers, so Mark got the
        message and started speaking in Spanish. 
       </div>
            
            
          `,
        answer: [
          "bit",
          "yawning",
          "grinned",
          "shook / nodded",
          "shook / nodded",
          "frowned",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the words below.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:25cm'>
          <b>1</b>&ensp;People will see it as a sign of # if you are yawning all the time.<br>
          <b>2</b>&ensp;If you bite your nails, people will think it’s a sign of #.<br>
          <b>3</b>&ensp;People will say you’re showing # if you purse your lips.<br>
          <b>4</b>&ensp;People will see it as a sign of # if you wave.<br>
          <b>5</b>&ensp;If you grimace, people will say you’re showing #.<br>
          <b>6</b>&ensp;If you raise your eyebrows, people will think it’s a sign of #.<br>
        </div>
            
            
          `,
        answer: [
          "boredom",
          "anxiety",
          "annoyance",
          "friendliness",
          "disgust",
          "surprise",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E4",
    audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.14 color=white colorimg='#d1b4d8'></headphone> Listen to four speakers. Match sentences (A–E)
with speakers (1–4). There is one extra sentence.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       This speaker:
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>A</b>&ensp;gets a shock when she hears some news.<br>
                <b>B</b>&ensp;thinks some instructions cause confusion.<br>
                <b>C</b>&ensp;is disappointed with someone’s performance.<br>
                <b>D</b>&ensp;is indifferent to an activity.<br>
                <b>E</b>&ensp;feels shame for what has happened. <br>
                
              </div>
              <div style='margin-left:100px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
             
            </div>
            
            
          `,
        InputRong: true,
        answer: ["3", "2", "4", "1", ""],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E5",
    audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "fear",
          "ignorance",
          "indifference",
          "interest",
          "shock",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.14 color=white colorimg='#d1b4d8'></headphone> Listen again and complete the sentences with the
emotions below. There are two extra emotions.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
            <b>1</b>&ensp;Speaker 1 feels # at their friend’s wish to go and see a musical.<br>
            <b>2</b>&ensp;Speaker 2 expresses his # about how he should use electrical devices.<br>
            <b>3</b>&ensp;Speaker 3 talks about someone who uses # to try and obtain things.<br>
            <b>4</b>&ensp;Speaker 4 says someone has to show more # in their work.<br>
        </div>

          `,
        answer: ["surprise", "ignorance", "fear", "interest"],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page24/E6/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:70px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:154px;left:70px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:197px;left:70px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P24-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
