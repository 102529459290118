import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E1",
    audio: "Audios/1.09.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 200, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=1.09 color=white colorimg='#d1b4d8'></headphone> Listen to sentences (1–5) and underline the
emphasised word(s).`,
        color: "black",
        left: -30,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 10px",
          // border: "2px solid",
          // color: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [
          "Do you want tea or coffee|?",
          "‘Is your number 07765|?’ ‘No, it’s 077|56|.’",
          "That one’s my jacket|. The leather one|.",
          "‘Is your name Mike|?’ ‘No, it’s Mark|.’",
          "Is that yours or mine|?",
        ],
        answers: ["0-6", "0-10", "1-16", "2-0", "2-12", "3-14", "4-4", "4-8"],
        initialValue: [],
      },
      Layout: `


      <div style=''>
        <b>1</b>&ensp; <input id=0 type=Circle /><br>
        <b>2</b>&ensp; <input id=1 type=Circle /><br>
        <b>3</b>&ensp; <input id=2 type=Circle /><br>
        <b>4</b>&ensp; <input id=3 type=Circle /><br>
        <b>5</b>&ensp; <input id=4 type=Circle /><br>
      </div>

     

        
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E2",
    audio: "Audios/1.10.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 500, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=1.10 color=white colorimg='#d1b4d8'></headphone> Read the <span style='color:rgb(196,11,68)'>Reading Strategy</span>. Listen to six
dialogues. Underline which words are emphasised in each
one. Match each one with a reason from the strategy
(a, b or c).`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { fontSize: 24, width: 50, textAlign: "center" },
        answers: ["a", "c", "a", "b", "c", "b"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          textDecoration: "underline",
          color: "black",
          // border: "2px solid",
          // borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          "Which are your shoes? <br> The ones with the laces|.",
          "I think I need a new phone|. <br> What? You’ve got a new phone?",
          "Can you give me a description of the thief, please? <br> Yes, he was tall and he had blonde_hair|.",
          "Shall we go shopping tomorrow or on Wednesday? <br> Let’s go tomorrow|.",
          "Don’t forget your keys|. <br> But they’re not my keys|. They must be Edward’s|.",
          "I’ll get your suitcase|. Is it the black one or the brown one? <br> The brown one|.",
        ],
        answers: [
          "0-18",
          "1-6",
          "2-28",
          "2-36",
          "3-8",
          "3-14",
          "4-18",
          "4-30",
          "5-16",
          "5-24",
          "5-32",
        ],
        initialValue: [],
      },
      Layout: `
         
      <div style='display:flex'>  
             <img src="img/FriendsPlus/Page18/E2/1.jpg" style='height:10cm'/>
             <div style='margin-left:50px'>
          
              <b>Anwers:</b><br>
              <b>1</b> #<br>
              <b>2</b> #<br>
              <b>3</b> #<br>
              <b>4</b> #<br>
              <b>5</b> #<br>
              <b>6</b> #<br>
          </div>
        </div>  
        <div style='display:flex'>  
          <b>1</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=0 type=Circle ></div>
             </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=1 type=Circle ></div>
             </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=2 type=Circle ></div>
             </div>
          </div>
           <div style='display:flex'>
            <b>4</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=3 type=Circle ></div>
             </div>
          </div>
        </div>
        <div>
         
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=4 type=Circle ></div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div style='margin-right:10px'><b>A</b> <br> <b>B</b></div>
                <div><input id=5 type=Circle ></div>
             </div>
          </div>
          
          
        `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      marginLeft: 20,
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Look at the lines of dialogue. Underline where the
emphasis should go and say which reason from the
 <span style='color:rgb(196,11,68)'>Reading Strategy</span> it matches with.`,
        width: "27cm",
        color: "black",
        left: -30,
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      marginLeft: 10,
      textAlign: "center",
      fontSize: 25,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["a", "b", "c"],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "461px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          marginLeft: 30,
          fontSize: 24,
          width: 400,
          textAlign: "center",
        },
        answers: [
          "sandwich, burger",
          "14, 40",
          "your, mine",
          "green, Light",
          "morning",
          "red, 5",
          "b",
          "c",
          "b",
          "a",
          "c",
          "a",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          textDecoration: "underline",
          color: "black",
          // border: "2px solid",
          // borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          "Do you want a sandwich or a burger|?",
          "Is your locker number 14|?",
          "No, number 40|.",
          "Is this your drink or mine|?",
          "Have you seen my scarf|? It’s green|. Light green|.",
          "Are you going to the leisure centre this afternoon|?",
          "No, this morning|.",
          "My house has a red door and it’s number 5|.",
        ],
        answers: [
          "0-18",
          "2-28",
          "2-36",
          "3-14",
          "4-30",
          "5-24",
          "5-32",
          "0-8",
          "2-4",
          "1-8",
          "3-4",
          "3-10",
          "0-14",
          "4-14",
          "4-18",
          "6-4",
          "7-8",
          "7-18",
        ],
        initialValue: [],
      },
      Layout: `
      
        <img src="img/FriendsPlus/Page18/E2/1.jpg" style='height:10cm'/>
         <div style='width:25cm'>
                    <b>1</b>&ensp;<input id=0 type=Circle /><br><div style='display:flex'>#<select id=6></select></div>
          <div style='display:flex'>
              <b>2</b> 
              <div style='margin-left:15px'>
                <b>A</b>&ensp;<input id=1 type=Circle /><br>
                <b>B</b>&ensp;<input id=2 type=Circle />
              </div>
          </div>
              <div style='display:flex'>#<select id=7></select></div>
                      <b>3</b>&ensp;<input id=3 type=Circle /><br><div style='display:flex'>#<select id=8></select></div>
                      <b>4</b>&ensp;<input id=4 type=Circle /><br><div style='display:flex'>#<select id=9></select></div>
          <div style='display:flex'>
              <b>5</b> 
              <div style='margin-left:15px'>
                <b>A</b>&ensp;<input id=5 type=Circle /><br>
                <b>B</b>&ensp;<input id=6 type=Circle />
              </div>
          </div>
              <div style='display:flex'>#<select id=10></select></div>
            <b>6</b>&ensp;<input id=7 type=Circle /><br><div style='display:flex'>#<select id=11></select></div>
          
          
        </div>
          
          
          
          
        `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E4",
    audio: "Audios/1.11.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=1.11 color=white colorimg='#d1b4d8'></headphone> Listen and check your answers to exercise 3.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `

          `,
        answer: [
          "bad as that",
          "faster we walk, the quicker",
          "healthier than it / not as unhealthy as it",
          "more comfortable than",
          "as old as",
          "like as spicy as",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E5",
    audio: "Audios/1.12.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 658, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=1.12 color=white colorimg='#d1b4d8'></headphone> Listen to the dialogue between two friends, Josh
and Max. Choose the correct answers (a–d).

`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["1-8", "0-8", "2-8", "3-4"],
        initialValue: [],
      },
      Layout: `

            <b>1</b>&ensp;At the beginning of the conversation, Josh
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>ignores Max. <br> feels ill. <br> pretends that everything is OK. <br> wants to go to chemistry class.</div>
                 </div>
            <b>2</b>&ensp;Josh tells Max
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>that he’s sorting out a problem. <br> that he’s made some new friends. <br> that he’s done something he regrets. <br> not to tell his father.</div>
                 </div>
            <b>3</b>&ensp;Max’s view is that
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>Josh’s dad will be angry with him. <br> Josh’s new friends are cool. <br> Josh should confess to his father. <br> Josh should challenge the boys.</div>
                 </div>
            <b>4</b>&ensp;Josh feels
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>his father doesn’t like Max. <br> grateful for Max’s support. <br> he can’t do anything about the situation. <br> Max’s advice isn’t helpful.</div>
                 </div>
              
        `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 149,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the words below.`,
        color: "black",
        left: 70,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page18/E6/1.jpg" style='height:20cm'/>
          <div style='position:absolute;top:123px;left:45px'>#</div>
          <div style='position:absolute;top:154px;left:414px'>#</div>
          <div style='position:absolute;top:217px;left:185px'>#</div>
          <div style='position:absolute;top:248px;left:133px'>#</div>
          <div style='position:absolute;top:280px;left:164px'>#</div>
          <div style='position:absolute;top:343px;left:517px'>#</div>
          <div style='position:absolute;top:406px;left:118px'>#</div>
          <div style='position:absolute;top:469px;left:119px'>#</div>
        </div>
        
          `,
        answer: [
          "nowhere",
          "like",
          "than",
          "more",
          "fewer",
          "far",
          "as",
          "the",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P18-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page18/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
