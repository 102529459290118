import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E1",
    exerciseKey: "img/FriendsPlus/Page62/Key/E1answerKey.png",
    component: Circle_Write,
    hintBox: [
      {
        src: [
          "find out",
          "hole up",
          "let on",
          "make out",
          "play on",
          "set up",
          "take in",
          "zoom in on",
        ],
        width: 950,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task above. Then choose the correct option (a or b)
in the sentences below.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b", "a <br> b"],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page62/E1/1.jpg" style='height:14.5cm'/><br>
        <div style='position:absolute;top:254px;left:657px'>
            <b>1</b>&ensp;The headline refers to how technology can
              <div style='display:flex'>
                <div style='margin-left:20px;margin-right:5px'><input id='0' type='Circle' /></div>
                <div>
                  cause certain crimes to increase.<br>
                  help to fight crime.<br>
                </div>
              </div>
            <b>2</b>&ensp;The photo shows how technology can
              <div style='display:flex'>
                <div style='margin-left:20px;margin-right:5px'><input id='1' type='Circle' /></div>
                <div>
                  cause certain crimes to increase.<br>
                  help to fight crime.<br>
                </div>
              </div>
        </div>
      </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E2",
    audio: "Audios/2-13.mp3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E2answerKey.png",
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: { width: 800, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.13 colorimg='#d1b4d8'></headphone> Listen to a student doing the task. Which of the 
          topics below does he mention?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          color: "#92278f",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
        },
        limitSelect: 4,
        listWords: [
          "artificial_intelligence car_crime computers credit_cards email_scams mobile_phones television",
        ],
        answers: ["0-2", "0-6", "0-8", "0-10"],
        initialValue: [],
      },
      Layout: `


      <input id=0 type='Circle'/>
      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E4",
    audio: "Audios/2-13.mp3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E4answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 40, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        width: 1000,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: -50,
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.13 colorimg='#d1b4d8'></headphone> Listen again. Complete these sentences from 
          the student’s presentation with the correct phrases from 
          exercise 3. Write a–e.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
        <div>
        <b>1</b>&ensp;#an email saying you’ve won millions in a lottery.<br>
        <b>2</b>&ensp;#cars are much harder to steal these days.<br>
        <b>3</b>&ensp;#criminals can use their knowledge of technology to commit crimes.<br>
        <b>4</b>&ensp;#people don’t commit crimes if there are cameras about.<br>
        <b>5</b>&ensp;#the mobile phone.<br>
      </div>
          `,
        answer: ["d", "b", "e", "c", "a"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E5",
    exerciseKey: "img/FriendsPlus/Page62/Key/E5answerKey.png",
    component: T6,
    isHello: true,
    // hideBtnFooter:true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 400,
      paddingTop: 2,
      // resize:'none'
    },
    stylesTextInput: { width: 40, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        width: 1000,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: -50,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the task above. Then make notes under the 
          headings below.`,
        color: "black",
        left: 80,
      },
    ],
    // hideBtnFooter: true,

    questions: [
      {
        title: `
      <div style='display:flex'>
      <img src="img/FriendsPlus/Page62/E5/1.jpg" style='height:23.5cm'/><br>
        <div>
          Examples of how friendships are strengthened <textarea id=0 rows=2 ></textarea>
          Examples of cyberbullying / lies <textarea id=1 rows=2 ></textarea>
          Examples of how social media campaigns can make a difference <textarea id=2 rows=2 ></textarea>

        </div>
      </div>
          `,
        answer: ["d", "b", "e", "c", "a"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E6",
    exerciseKey: "img/FriendsPlus/Page62/Key/E6answerKey.png",
    component: T6,
    isHello: true,
    // hideBtnFooter:true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      // resize:'none'
    },
    stylesTextInput: { width: 40, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        width: 1000,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: -50,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5. 
          Remember to include phrases from exercise 3.`,
        color: "black",
        left: 80,
      },
    ],
    hideBtnFooter: true,

    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <textarea id=0 rows=5></textarea>
        </div>
          `,
        answer: [""],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P62-E3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    inputSize: 150,
    textareaStyle: { width: 950, paddingTop: 3 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["instance", "kind", "that", "what", "words"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct preposition and 
          your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:25cm'>
          <b>a</b>&ensp;One example of# would be …<br>
          <b>b</b>&ensp;So, for#  , …<br>
          <b>c</b>&ensp;#I mean by that is …<br>
          <b>d</b>&ensp;The# of thing I’m talking about is …<br>
          <b>e</b>&ensp;In other#  , …<br>
        </div>
          `,
        answer: ["that", "instance", "What", "kind", "words"],
      },
    ],
  },
};

export default json;
