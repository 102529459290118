import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E1",
    // audio: "Audios/1.15.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 40,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct linkers (a–c) to complete the text.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-0", "3-0", "4-0", "5-4", "6-8", "7-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page66/E1/1.jpg" style='height:13cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>all the same <br> incidentally <br> for instance</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>you know <br> nevertheless <br> well</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>all the same <br> by the way <br> for instance</div>
                            </div>
                          </div>
                          <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>Well <br> Nevertheless <br> As I was saying</div>
                            </div>
                      </div>
                          </div>
                          <div>
                          
          
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>Mind you <br> For instance <br> Talking of which</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>All the same <br> By the way <br> However</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>Still <br> Incidentally <br> For instance</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>8</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=7 type=Circle ></div>
                <div style='margin-left:10px'>even so <br> for instance <br> by the way</div>
             </div>
          </div>
          </div>
          </div>
        `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E2",
    audio: "Audios/2.14.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 300, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.14 color=white colorimg='#d1b4d8'></headphone> Listen and check your answers to exercise 1.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
           
            
          `,
        // InputRong: true,
        answer: [
          "see",
          "'ll have been",
          "'ve arrived / arrive",
          "decide",
          "'m visiting / 'll be visiting",
          "gets",
          "'ll be talking",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E3",
    // audio: "Audios/1.22.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 350,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#D34A74'>Listening Strategy</span> and match the
sentence halves.

`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    character: /[/,]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;I am not sure who to vote for.<br>
                <b>2</b>&ensp;We couldn’t go to London to demonstrate.<br>
                <b>3</b>&ensp;I don’t really understand how Twitter works.<br>
                <b>4</b>&ensp;My sister went on a protest march at the weekend.<br>
                <b>5</b>&ensp;Scientists say it will be difficult to reverse global warming.<br>
              </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
              </div>
              <div>
                 <b>a</b>&ensp;Nevertheless, I will try to use it.<br>
                 <b>b</b>&ensp;Even so, that’s no excuse not to do it at all.<br>
                 <b>c</b>&ensp;Still, we managed to protest effectively in other ways.<br>
                 <b>d</b>&ensp;Talking of protests, did you sign that petition about equal rights?<br>
                 <b>e</b>&ensp;All the same, it would be better to try to do something about it.<br>
               </div>

            
            
          `,
        answer: ["b", "c", "a", "d", "e"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E4",
    audio: "Audios/2.15.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.15 color=white colorimg='#d1b4d8'></headphone> Listen and check your answers.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: /[/|]/,
    hideBtnFooter: true,
    questions: [
      {
        title: `

     
          `,
        answer: [
          "take suncream, I won't",
          "case it rains later",
          "I've finished packing / I finish packing, I'll",
          "the time you hear from",
          "he doesn't find his passport",
          "that you don't snore|you don't snore",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E5",
    audio: "Audios/2.16.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 100, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["able", "might", "mustn’t", "needn’t", "should (x2)", "supposed"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name=2.16 color=white colorimg='#d1b4d8'></headphone> Listen and match two speakers with
sentences (A–C). There is one extra sentence.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: ",",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
              <div>
                 <b>A</b>&ensp;talks about a particular online protest and how it evolved into something else.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>B</b>&ensp;proves that social media is essential in political campaigns.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>C</b>&ensp;feels that there is no division between social media campaigning and other forms of protest.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
           
        </div>
        
            
            
          `,
        InputRong: true,
        answer: ["2", "", "1"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E6",
    audio: "Audios/1.23.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=1.23 color=white colorimg='#d1b4d8'></headphone> Listen to the conversation. Are the sentences true
(T) or false (F)?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
               <div>
                 <b>1</b>&ensp;The conversation is happening in a restaurant. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;John reacts to both of Ella’s suggestions with decisiveness. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;Ella would prefer to spend the day sightseeing. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;John is worried the bus might be too hot. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;Ella’s attitude is that you should make the most of a trip away <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
             
            </div>

            
            
          `,
        // InputRong: true,
        answer: ["F", "F", "T", "T", "T"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P66-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page66/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
