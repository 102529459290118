import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";

const json = {
  1: {
    unit: "Fluency Time! 4",
    id: "12WB-2024-FT4-P96-E1",
    audio: "Audios/Page96/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='152' src='Audios/Page96/tieude-e1.mp3'></headphone>",
        color: "black",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page96/E1/1.jpg",
          audioUrl: "Audios/Page96/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page96/E1/2.jpg",
          audioUrl: "Audios/Page96/hinh2.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 4",
    id: "12WB-2024-FT4-P96-E3",
    audio: "",
    video: "",
    component: Select_Image,
    exerciseKey: "img/FriendsPlus/Page96/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `Listen. Check (✓) the things they like and cross (✗) the things they don’t like. <headphone name='153' src='Audios/Page96/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>`,
        color: "black",
      },
    ],
    selectStyle: {
      textAlign: "center",
      height: "1cm",
    },
    selectGroupOption: [
      [`img/FriendsPlus/Page96/E2/2.jpg`, `img/FriendsPlus/Page96/E2/3.jpg`],
      [`img/FriendsPlus/Page96/E2/2.jpg`, `img/FriendsPlus/Page96/E2/3.jpg`],
      [`img/FriendsPlus/Page96/E2/2.jpg`, `img/FriendsPlus/Page96/E2/3.jpg`],
      [`img/FriendsPlus/Page96/E2/2.jpg`, `img/FriendsPlus/Page96/E2/3.jpg`],
      [`img/FriendsPlus/Page96/E2/2.jpg`, `img/FriendsPlus/Page96/E2/3.jpg`],
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div><img src='img/FriendsPlus/Page96/E2/1.jpg' alt='' style='height:5cm'/></div>
            <div style='position: absolute; top: 65px; left: 460px;'><select id='0' group='0'  width='200' height='40'></select></div>
            <div style='position: absolute; top: 65px; left: 684px;'><select id='1' group='0'  width='200' height='40'></select></div>

            <div style='position: absolute; top: 115px; left: 240px;'><select id='2' group='1'  width='200' height='40'></select></div>
            <div style='position: absolute; top: 115px; left: 460px;'><select id='3' group='1'  width='200' height='40'></select></div>
            <div style='position: absolute; top: 115px; left: 684px;'><select id='4' group='1'  width='200' height='40'></select></div>

            
          </div>
        `,
        answer: [
          `img/FriendsPlus/Page96/E2/2.jpg`,
          `img/FriendsPlus/Page96/E2/3.jpg`,

          `img/FriendsPlus/Page96/E2/3.jpg`,
          `img/FriendsPlus/Page96/E2/2.jpg`,

          `img/FriendsPlus/Page96/E2/2.jpg`,
        ],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 4",
    id: "12WB-2024-FT4-P96-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Talk with your friend. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "black",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page96/E3/1.jpg",
        },
        // {
        // 	url: 'img/FriendsPlus/Page96/E1/2.jpg',
        // 	audioUrl: 'Audios/Page96/hinh2-e1-p30.mp3',
        // },
      ],
    ],
  },
};

export default json;
