import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E1",
    audio: "Audios/2.21.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.21 color=white colorimg='#d1b4d8'></headphone> Listen to four speakers talking about having their
photo in a newspaper or a magazine. Match speakers (1–4)
with sentences (A–E). There is one extra sentence.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:24cm'>
              <div>
                 <b>A</b>&ensp;He/She was critical of the person who took the photo. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>B</b>&ensp;He/She was disappointed about the way the photo had turned out. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>C</b>&ensp;He/She was indifferent about having the photo taken. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>D</b>&ensp;He/She was enthusiastic about posing for photos. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>E</b>&ensp;He/She was impressed with the photographer. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
                 
                 </div>
              
           
        </div>
          `,
        InputRong: true,
        answer: ["", "4", "1", "3", "2"],
      },
    ],
  },
  2: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E2answerKey.png",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["accommodation", "clothes", "food and drink", "what to see"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Work in pairs. Ask and answer the questions.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
             <div>
              <b>1</b>&ensp;What job would you like to apply for when you graduate from high school or university?<br>
              <b>2</b>&ensp;Why do you think you are suitable for that job?<br>
              <b>3</b>&ensp;What should you do to prepare yourself for your future career?<br>
             </div>
          `,
        answer: [
          "I had never met",
          "he wouldn't be exhausted",
          "if they had studied harder",
          "You would be poor",
          "she wouldn't be burned now",
          "I knew your address",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      marginTop: 10,
      // fontWeight: "bold",
      marginLeft: 25,
      width: 500,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the text on the next page and choose the
correct answers (a–d).
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-4", "1-8", "2-4", "3-12"],
        initialValue: [],
      },
      Layout: `


            <b>1</b>&ensp;Since 1970, the Bank of England has been printing banknotes featuring
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>kings and queens on the back. <br> famous people from the past on the back. <br> fictional characters on the back. <br> current celebrities on the back.</div>
                 </div>
            <b>2</b>&ensp;Caroline Criado-Perez and her supporters were protesting because 
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>they didn’t think Winston Churchill should replace Elizabeth Fry. <br> they wanted more representatives from English literature on the banknotes. <br> they didn’t want all of the notes to feature men. <br> they thought women should be represented on more than one banknote.</div>
                 </div>
            <b>3</b>&ensp;Caroline Criado-Perez closed one of her social media accounts because 
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>she was receiving too many messages. <br> she was being harassed by her opponents. <br> she was being stalked by one of her supporters. <br> she was resigning from the campaign.</div>
                 </div>
            <b>4</b>&ensp;The new selection process for future banknote characters will be 
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>more focused on the arts. <br> quicker to respond to criticism. <br> more representative of women. <br> more democratic than before.</div>
                 </div>
           <div style='width:23cm;margin-top:10px'>
                You’d think it would be quite straightforward to choose a
historical figure to include on a banknote, wouldn’t you?
Of course, the current monarch needs to be on the front of
the note; the problem is who should appear on the back.
Back in 1970, when the Bank of England began featuring
past celebrities on its £5, £10, £20 and £50 notes, nobody
used to question the choices. But that changed in 2013,
when it was announced that the £10 note was going to be
redesigned.<br>
Just before this announcement, former British Prime Minister
Winston Churchill had been chosen to replace prison
reformer Elizabeth Fry on the new £5 note. The fact that
there were now no women on the back of the banknotes
made a woman the logical choice for the £10 note. Feminist
activist Caroline Criado-Perez led a campaign to put pressure
on the Bank of England to choose the novelist Jane Austen
for the note. Criado-Perez and her supporters organised
protests, sent out an online petition – signed by 35,000
people – and threatened legal action if their demands were
not met.<br>
This was only the start of the trouble, however. On the day
the Bank of England announced that the new £10 note
would, indeed, feature the author, social media sites were
flooded with angry comments. There were attacks on the
Bank of England for being too cautious in their choice,
attacks on Jane Austen for being old-fashioned and, worse
still, personal attacks on Criado-Perez and her supporters
for promoting the choice of a woman. When she began to
receive death threats, the activist closed her Twitter account.<br>
In response to the incident, the Bank of England announced
that it would review the selection process in order to avoid
such ugly reactions in future. Since then, a special committee
has been set up to deal with the process. From now on,
whenever a banknote has to be changed, the committee will
choose a particular field, for example the visual arts, and the
public will have two months to send in ideas. After that, a
shortlist of candidates will be compiled and the governor of
the Bank of England will choose a character from the list.

           </div>
            
           
              
        `,
    },
  },
  4: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with one word in each gap to make
mixed conditional sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page76/E4/1.jpg" style='height:14cm'/>
          <div style='position:absolute;top:129px;left:641px'>#</div>
          <div style='position:absolute;top:162px;left:280px'><input width=179px /></div>
          <div style='position:absolute;top:196px;left:44px'><input width=179px /></div>
          <div style='position:absolute;top:231px;left:152px'><input width=179px /></div>
          <div style='position:absolute;top:299px;left:224px'><input width=179px /></div>
          <div style='position:absolute;top:368px;left:393px'><input width=179px /></div>
          
         
         
         
        </div>
        `,
        answer: ["have", "wasn't / weren't", "hadn't", "wouldn't", "be", "had"],
      },
    ],
  },
  5: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the correct form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
          Oxfam is a world leader in delivering emergency
          relief. If it weren’t for this charity, thousands of
          children in Europe <sup>1</sup>#(go) hungry
          during World War II. Today, desperate people
          <sup>2</sup>#(not have) clean water if Oxfam hadn’t made
          this a priority during humanitarian disasters. And if it
          <sup>3</sup>#(not be) for Oxfam, the NGO ActionAid might
          not exist either; there wouldn’t be so much pressure on
          governments to provide healthcare and education and to
          combat climate change if these charities <sup>4</sup>#(not develop). 
          But Oxfam might not be so successful nowadays if
          it <sup>5</sup>#(not recognise) the importance of linking
          business with charity. Oxfam employs thousands of people
          worldwide, but it couldn’t have become the UK’s biggest
          high-street seller of second-hand books if it <sup>6</sup>#(not be) 
          also for the thousands of volunteers who give their
          time working in these important charity shops.

         
          
         
         
         
        </div>
        `,
        answer: [
          "would have gone",
          "would not have|wouldn't have",
          "had not been|hadn't been",
          "had not developed|hadn't developed",
          "had not recognised / did not recognise|hadn't recognised / didn't recognise",
          "was not / were not|wasn't / weren't",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page76/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Cumulative Review 3",
    id: "12WB-2024-CR3-P76-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page76/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
