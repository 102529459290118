import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,

      background: "none",
      width: 950,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "admit",
          "announce",
          "argue",
          "complain",
          "<s>deny</s>",
          "insist",
          "promise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Report the sentences using the reporting verbs below
and <i>that</i>.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>

            <b>1</b>&ensp;Ian said ‘I didn’t leave the door open.’<br>
                  <div style='margin-left:25px'><span style='color:rgb(27,117,137)'>Ian denied that …</span> <input margin-left=0px width=500px /></div>
            <b>2</b>&ensp;‘I think it would be dangerous to go out in this weather,’ said George.<br>#<br>
            <b>3</b>&ensp;Molly said, ‘The traffic is so noisy that I can’t hear myself think!’<br>#<br>
            <b>4</b>&ensp;‘I’ve decided to dye my hair orange,’ said Mike.<br>#<br>
            <b>5</b>&ensp;‘Don’t worry. I definitely won’t tell anyone your secret,’ Laura told me.<br>#<br>
            <b>6</b>&ensp;Jackie said, ‘This can’t be my bag because it’s the wrong colour.’<br>#<br>
            <b>7</b>&ensp;‘Yes, it was me who took the money,’ said Fred.<br>#<br>

          `,
        answer: [
          "he hadn't left the door open.",
          "George insisted that it would be dangerous to go out in that weather. / George argued that it would be dangerous to go out in that weather.",
          "Molly complained that the traffic was so noisy that she couldn't hear herself think.",
          "Mike announced that he'd decided to dye his hair orange.",
          "Laura promised  that she wouldn't tell anyone my secret.|Laura promised me that she wouldn't tell anyone my secret.",
          "Jackie argued that it couldn't be her bag because it was the wrong colour. / Jackie insisted that it couldn't be her bag because it was the wrong colour.",
          "Fred admitted that it had been him who had taken the money",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E2",
    audio: "Audios/2.11.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    maxLength: 1,
    stylesTextInput: {
      // borderBottom: "none"
      border: "2px solid",
      borderRadius: 5,
      background: "none",
      width: 38,
      height: 35,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.11 color=white colorimg='#d1b4d8'></headphone> Listen and match the speakers with the reported
speech.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='width:26cm'>
          <b>a</b>&ensp;She complained that the weather was too hot. #<br>
          <b>b</b>&ensp;She agreed that the weather was too hot. #<br>
          <b>c</b>&ensp;She explained that she would be late. #<br>
          <b>d</b>&ensp;She promised that she wouldn’t be late. #<br>
          <b>e</b>&ensp;She denied that she was ill. #<br>
          <b>f</b>&ensp;She insisted that she was ill. #<br>
          <b>g</b>&ensp;She argued that it was too expensive. #<br>
          <b>h</b>&ensp;She agreed that it was too expensive. #<br>
      </div>
            
            
          `,
        answer: ["1", "2", "4", "3", "5", "6", "8", "7"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      marginLeft: 20,
      width: 700,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the words in the correct order to make sentences.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div style='width:26cm'>
            <b>1</b>&ensp;to / for / invited / lunch / them / they / us / join <br>#<br>
            <b>2</b>&ensp;helping / escape / to / denied / thieves / he / the <br>#<br>
            <b>3</b>&ensp;into / on / lift / she / me / insisted / town / giving / a <br>#<br>
            <b>4</b>&ensp;that / picnic / have / suggested / should / a / Jo / we <br>#<br>
            <b>5</b>&ensp;Ned / losing / me / tickets / blamed / for / our <br>#<br>
            <b>6</b>&ensp;tomorrow / that / to / me / she / promised / lend / DVD <br>#<br>
            <b>7</b>&ensp;answer / that / didn’t / admitted / she / Nina / the / know<br>#<br>
       </div>
          `,
        answer: [
          "They invited us to join them for lunch.",
          "He denied helping the thieves to escape.",
          "She insisted on giving me a lift into town.",
          "Jo suggested that we should have a picnic.",
          "Ned blamed me for losing our tickets.",
          "She promised to lend me that DVD tomorrow.",
          "Nina admitted that she didn't know the answer.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Correct the underlined mistakes in the sentences. Use the
correct reporting structures.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      marginLeft: 20,
      width: 700,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='width:26cm'>
            <b>1</b>&ensp;She <u>begged him not telling</u> anyone what he’d heard.<br>#<br>
            <b>2</b>&ensp;I <u>suggested to meet him</u> outside the cinema at eight.<br>#<br>
            <b>3</b>&ensp;Belinda <u>reminded me not forgetting</u> the festival tickets.<br>#<br>
            <b>4</b>&ensp;The teacher <u>accused Jon to cheat</u> in the test.<br>#<br>
            <b>5</b>&ensp;Suzie <u>apologised of not remembering</u> to invite me.<br>#<br>
            
       </div>
          `,
        answer: [
          "begged him not to tell",
          "suggested meeting him",
          "reminded me not to forget",
          "accused Jon of cheating",
          "apologised for not remembering",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 250,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write a second sentence that has a similar meaning to the
first. Use the words in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
            <b>1</b>&ensp;Nick boasted #(come) top in the test.<br>
            <b>2</b>&ensp;Our teacher forbade us #(use) a dictionary.<br>
            <b>3</b>&ensp;My dad criticised me #(spend) too much.<br>
            <b>4</b>&ensp;His neighbour demanded #(he / turn down) his music.<br>
            <b>5</b>&ensp;She warned us #(not / stay) in that part of town.<br>
            <b>6</b>&ensp;He confessed #(sell) stolen goods.<br>
            <b>7</b>&ensp;I proposed #(we / find) somewhere to have a coffee.<br>
            <b>8</b>&ensp;Clara insisted #(give) them a hand with the cooking.<br>
        </div>
          
    `,
        answer: [
          "about coming",
          "to use",
          "for spending",
          "he turn down|that he turn down",
          "not to stay",
          "to selling",
          "we find|that we find",
          "on giving",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      resize: "none",
      // marginBottom: -20,
      marginTop: 10,
      width: 900,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Report the dialogue in your notebook using appropriate
reporting verbs. There may be more than one answer.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='display:flex'>
            <div>
              <b>Greg</b><br>
              <b>Katya</b><br>
              <b>Greg</b><br>
              <b>Katya</b><br>
              <b>Greg</b><br><br>
              <b>Katya</b><br>
              <b>Greg</b><br>
              <b>Katya</b><br>
              
            </div>
            <div style='margin-left:20px;width:20cm'>
                  Hi, Katya. I’m so sorry I didn’t come to your barbecue
                yesterday.
                <br> Actually, I was quite upset about
                it, Greg.
                <br> It really wasn’t my fault!
                <br> I won’t listen to any more
                of your lies, Greg.
                <br> But I couldn’t come
                because I broke my wrist
                and had to go to hospital!
                <br> Oh, I see … Well, why
                don’t you come over this
                afternoon?
                <br> Great – and I’ll definitely be there
                this time!
                <br> OK. Oh, and don’t forget to bring my birthday
                present with you!

            </div>
       </div>
       <textarea id=0 rows=7></textarea>
          `,
        answer: [
          `Greg apologised to Katya for not going to her barbecue the day before. Katya admitted to Greg that she'd been quite upset about it. Greg insisted / argued that it hadn't been his fault. Katya refused to listen to any more of his / Greg's lies. Greg explained that he hadn't been able to go because he'd broken his wrist and had had to go to hospital. Katya suggested that he go / went over that afternoon. Greg promised that he’d definitely be there that time. Katya reminded him to take her birthday present with him.`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P59-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page59/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
