import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Change the direct speech into reported speech.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div><b>1</b>&ensp;‘You must take these tablets twice a day.’</div> 
              <div style='margin-left:25px'>
                The doctor says I <input width=550px />. 
              </div>
          
              <div><b>2</b>&ensp;‘I’m going to the gym tomorrow.’ </div>
              <div style='margin-left:25px'>
                Ben told me <input width=602px />. 
              </div>
          
              <div><b>3</b>&ensp;‘You don’t have to wait for me.’</div> 
              <div style='margin-left:25px'>
                Liz said that I <input width=591px />. 
              </div>
          
              <div><b>4</b>&ensp;‘I took my final exam yesterday.’</div> 
              <div style='margin-left:25px'>
                Jack told me <input width=600px />. 
              </div>
          
              <div><b>5</b>&ensp;‘I haven’t told you the complete truth.’</div> 
              <div style='margin-left:25px'>
                Pablo said <input width=620px />. 
              </div>
          
              <div><b>6</b>&ensp;‘I’ll meet you later today.’</div> 
              <div style='margin-left:25px'>
                Jim tells me <input width=605px />. 
              </div>
              <div><b>7</b>&ensp;‘I prefer this café to the one opposite.’</div> 
              <div style='margin-left:25px'>
                Julia says <input width=631px />. 
              </div>
              <div><b>8</b>&ensp;‘I’ll always remember meeting you.’</div> 
              <div style='margin-left:25px'>
                Ann told me <input width=595px />. 
              </div>

          `,
        answer: [
          "must take these tablets twice a day.",
          "that he was going to the gym the next day.| he was going to the gym the next day.",
          "didn't have to wait for her.|did not have to wait for her.",
          "that he had taken his final exam the day before.| he had taken his final exam the day before.",
          "that he had not told me the complete truth.|that he hadn't told me the complete truth.| he hadn't told me the complete truth.|he had not told me the complete truth.",
          "that he will meet me later today.|that he'll meet me later today.| he'll meet me later today.|he will meet me later today.",
          "that she prefers this café to the one opposite.| she prefers this café to the one opposite.",
          "that she would always remember meeting me.| she would always remember meeting me.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the
words in brackets. Do not change the order of the words.
Use a maximum of five words including the words in
brackets.`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='width:26cm'>
          <b>1</b>&ensp;Will asked us #(time / game / start) the next day.<br>
          <b>2</b>&ensp;The assistant asked him if #(he / like / try) on a smaller jacket.<br>
          <b>3</b>&ensp;My teacher asked me #(I / finish) the exercise and I said I hadn’t.<br>
          <b>4</b>&ensp;I asked Maria #(which / film / seen) the night before.<br>
          <b>5</b>&ensp;The dentist asked #(me / why) never used an electric toothbrush before.<br>
          <b>6</b>&ensp;She asked him if #(he / going) the park and he said he wasn’t.<br>
          <b>7</b>&ensp;I asked the policeman whether #(he / help / us) and he said he could.<br>
      </div>
            
            
          `,
        answer: [
          "what time the game started",
          "he would like to try",
          "if I had finished",
          "which film she had seen",
          "me why I had",
          "he was going to",
          "he could help us",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      marginLeft: 20,
      width: 800,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Change the direct speech into reported speech. Begin
with <i>My mum asked me</i>.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style='width:26cm'>
            <b>1</b>&ensp;‘Why are you home so late?’<br>#<br>
            <b>2</b>&ensp;‘Did you leave school on time today?’<br>#<br>
            <b>3</b>&ensp;‘When are you going to tidy your room?’<br>#<br>
            <b>4</b>&ensp;‘Have you seen your brother this afternoon?’<br>#<br>
            <b>5</b>&ensp;‘Will you help me with the shopping tomorrow?’<br>#<br>
            <b>6</b>&ensp;‘Would you like me to give you some money for a pizza?’<br>#<br>
       </div>
          `,
        answer: [
          "My mum asked me why I was home so late.",
          "My mum asked me if I had left school on time that day.",
          "My mum asked me when I was going to tidy my room.",
          "My mum asked me if I had seen my brother that afternoon.",
          "My mum asked me if I'd help her with the shopping the next day.",
          "My mum asked me if I'd like her to give me some money for a pizza.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Report the dialogue using <i>say</i> or <i>ask</i> in the past simple.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
    <img src="img/FriendsPlus/Page57/E4/1.jpg" style='height:10cm'/>
       <div style='display:flex'>
            <div>
              <b>Dan</b><br>
              <b>Zoe</b><br>
              <b>Dan</b><br>
              <b>Zoe</b><br>
              <b>Dan</b><br>
              <b>Zoe</b><br>
              <b>Dan</b><br>
              <b>Zoe</b><br>
              
            </div>
            <div style='margin-left:20px;width:20cm'>
                  Are you going to the concert tomorrow evening?
                  <br> No, I’m not. I haven’t got any money.
                  <br> I’ll lend you some. How much do you need?
                  <br> That’s really kind of you, but I have to finish my
                  homework too.
                  <br> Would you like me to help you finish it?
                  <br> Do you speak German?
                  <br> No, I don’t. Your cousin does, doesn’t she?
                  <br> Yes, she does. Perhaps I should ask her.

            </div>
       </div>
       <textarea id=0 rows=7></textarea>
          `,
        answer: [
          "Dan asked Zoe if she was going to the concert the next evening. Zoe said she wasn't. She said she didn't have any money. Dan said he'd lend her some. He asked her how much she needed. Zoe said that was really kind of him, but she had to finish her homework too. Dan asked her if she'd like him to help her finish it. Zoe asked him if he spoke German. Dan said he didn't. He asked her if her cousin did. Zoe said she did. She said perhaps she should ask her.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 800,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write a second sentence that has a similar meaning to the
first. Use the words in brackets.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;‘Do you know the answer to these questions?’ Sally asked me. (if)<br>#<br>
            <b>2</b>&ensp;‘I mustn’t be late for the trip tomorrow,’ said Bella. (next)<br>#<br>
            <b>3</b>&ensp;‘Have you heard the band’s new album yet?’ asked Kostas. (me)<br>#<br>
            <b>4</b>&ensp;‘I won’t be at school next week,’ said Mark. (says)<br>#<br>
            <b>5</b>&ensp;‘My plane arrives at ten in the morning,’ said Andrew. (tells)<br>#<br>
        </div>
          
    `,
        answer: [
          "Sally asked me if I knew the answer to the questions / Sally asked me if I knew the answer to those questions.",
          "Bella said that she mustn't be late for the trip the next day.",
          "Kostas asked me if I'd heard the band's new album yet.",
          "Mark says that he won't be at school next week.",
          "Andrew tells me that his plane arrives at ten in the morning.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Match the adjectives below with the descriptions.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        1 ‘Do you know the answer to these questions?’ Sally asked
me. (if)
2 ‘I mustn’t be late for the trip tomorrow,’ said Bella. (next)
3 ‘Have you heard the band’s new album yet?’ asked
Kostas. (me)
4 ‘I won’t be at school next week,’ said Mark. (says)
5 ‘My plane arrives at ten in the morning,’ said Andrew. (tells)
        <div style='width:26cm'>
            <b>1</b>&ensp;Someone who is honest and says what they think.#<br>
            <b>2</b>&ensp;Someone who does not support or help either side in a disagreement.#<br>
            <b>3</b>&ensp;Something which is not genuine.#<br>
            <b>4</b>&ensp;Someone who can be relied upon to do the right thing and to be honest.#<br>
            <b>5</b>&ensp;Someone who says only what is true. #<br>
        </div>
          
    `,
        answer: ["straight", "neutral", "fake", "trustworthy", "truthful"],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 7",
    id: "12WB-2024-U7-P57-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
