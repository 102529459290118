import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E1",
    audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 40, paddingTop: 7 },
    hintBox: [
      {
        src: ["bonds", "touch", "time", "commitment", "isolation"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task above and the article below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div style=''>
              <img src="img/FriendsPlus/Page31/E1/1.jpg" style='height:4cm'/>
              <b>1</b>&ensp;describe a personal experience?#<br>
              <b>2</b>&ensp;list reasons to keep the bus service?#<br>
              <b>3</b>&ensp;give a reason for writing the letter?#<br>
              <b>4</b>&ensp;suggest that money isn’t the only consideration?#<br> <br>
            </div>
            <img src="img/FriendsPlus/Page31/E1/2.jpg" style='height:20cm'/> <br>
          </div>
          

        
        
          
          `,
        // InputRong: true,
        answer: ["C", "B", "A", "D"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 200, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["far", "now", "something", "subject"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the
verbs in brackets.`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <b>1</b>&ensp;Having #(run) to catch the bus, I got to the party in time.<br>
          <b>2</b>&ensp;#(travel) together on the train, we often chatted.<br>
          <b>3</b>&ensp;As the tourists #(arrive), the tourist office opened.<br>
          <b>4</b>&ensp;After #(finish) his coffee, he went to the cinema.<br>
          <b>5</b>&ensp;#(wait) for you, I read a book.<br>
          <b>6</b>&ensp;Having #(make) the cake, they began to eat it.<br>
          <b>7</b>&ensp;After #(swim) in the river, they sunbathed for an hour.<br>
          

        
        
          
          `,
        // InputRong: true,
        answer: [
          "run",
          "Travelling",
          "arrived",
          "finishing",
          "Waiting",
          "made",
          "swimming",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E3",
    // audio: "Audios/1.20.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // margin: "0px 10px",
      background: "none",
      width: 700,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Find an example of one of the structures from exercise 2
in the blog post.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Answer:</b>#

       
        
          
          `,
        // InputRong: true,
        answer: ["Travelling to town, I chatted to the other passengers."],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E4",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the task above. Make some notes for a) the
advantages of the tourist office and b) your account.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          Advantages<textarea id=0 rows=2></textarea>
        </div>
        <div style='display:flex'>
          Your account<textarea id=1 rows=2></textarea>
        </div>
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 50, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write your blog post. Follow the paragraph plan in
exercise 1.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page31/E1/2.jpg" style='height:22cm'/>
        <textarea id=0 rows=7></textarea>
          `,
        answer: ["d", "b", "e", "a", "c"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page31/E6/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:108px;left:75px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:154px;left:75px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:199px;left:75px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  7: {
    unit: "Unit 3",
    id: "12WB-2024-U3-P31-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page31/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
