import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: ["be", "not have", "not use", "not want"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct answers (a–c) to complete the
sentences.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-0", "2-8", "3-4", "4-8"],
        initialValue: [],
      },
      Layout: `



            <b>1</b>&ensp;On our trip, we visited a shop selling furniture.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>old beautiful Chinese <br> Chinese beautiful old <br> beautiful old Chinese</div>
                 </div>
            <b>2</b>&ensp;We ordered some olives with our meal.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>small black Greek <br> Greek black small <br> black small Greek</div>
                 </div>
            <b>3</b>&ensp;My parents are buying a sofa next week.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>new red comfortable <br> red comfortable new <br> comfortable new red</div>
                 </div>
            <b>4</b>&ensp;Our favourite singer has just married a politician.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>middle-aged German tall <br> tall middle-aged German <br> German tall middle-aged</div>
                 </div>
            <b>5</b>&ensp;In the story, a girl finds the key to a box.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>strange black antique <br> black strange antique <br> strange antique black</div>
                 </div>
           
              
        `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E2",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 250, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cook", "learn", "look", "travel", "wait", "work"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct answers (a or b) to complete the text.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page44/E2/1.jpg" style='height:19cm'/>

                      <div style='display:flex'>  
                      <div style='margin-right:200px'>  
                      <div style='display:flex'>  
                          <b>1</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=0 type=Circle ></div>
                                <div style='margin-left:10px'>Welsh amazing <br> amazing Welsh</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>2</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=1 type=Circle ></div>
                                <div style='margin-left:10px'>clever four-year-old <br> four-year-old clever</div>
                            </div>
                          </div>

                          <div style='display:flex'>
                            <b>3</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=2 type=Circle ></div>
                                <div style='margin-left:10px'>Indian young <br> young Indian</div>
                            </div>
                          </div>
       
                          </div>
                          <div>
                          
           <div style='display:flex'>
                            <b>4</b>
                            <div style='display:flex;margin-left:15px' >
                                <div><input id=3 type=Circle ></div>
                                <div style='margin-left:10px'>new German <br> German new</div>
                            </div>
          </div>
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>remarkable little <br> little remarkable</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>black tiny <br> tiny black</div>
             </div>
          </div>
          
          </div>
          </div>
        `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E3",
    // audio: "Audios/1.26.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },

    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 250,
      // padding: 0,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "ancient",
          "famous",
          "Greek",
          "Italian",
          "tiny",
          "Turkish",
          "white",
          "young",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete each sentence with the most appropriate two
adjectives below in the correct order.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>

       <div style=''> 
            <b>1</b>&ensp;The red and # flag has a moon and a star on it.<br>
            <b>2</b>&ensp;The Parthenon is a(n) # temple in Athens.<br>
            <b>3</b>&ensp;She picked up the # boy to stop him from crying.<br>
            <b>4</b>&ensp;Michelangelo was a(n) # painter.<br>
          </div>
         
            
            
          `,
        answer: [
          "white Turkish",
          "ancient Greek",
          "tiny young",
          "famous Italian",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E4",
    // audio: "Audios/1.21.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box. box and the dictionary entry.
Choose the correct adjective. If both are correct, put a tick.

`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 400,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "afraid / frightened",
          "alive / living",
          "angry / annoyed",
          "a_lonely / an_alone",
          "glad / happy",
          "asleep / sleeping",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
          "✓",
        ],
        answers: ["6-0", "1-4", "8-0", "3-0", "4-4", "11-0"],
        initialValue: [],
      },
      Layout: `
            <div style='line-height:50px;'>
            <b>1</b>&ensp;Don’t be <input  id=0 type=Circle />; the dog won’t hurt you. <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=6 type=Circle /></span> <br>
            <b>2</b>&ensp;His only <input  id=1 type=Circle /> relatives live in New Zealand. <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none"  id=7 type=Circle /></span> <br>
            <b>3</b>&ensp;Are you <input  id=2 type=Circle /> because I’m late? <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none" id=8 type=Circle /></span> <br>
            <b>4</b>&ensp;It’s <input  id=3 type=Circle /> life on that tiny island.  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none" id=9 type=Circle /></span> <br>
            <b>5</b>&ensp;The <input  id=4 type=Circle /> students celebrated the end of their exams with a party.<span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white' ><input border="none"  id=10 type=Circle /></span> <br>
            <b>6</b>&ensp;Shut the door quietly – your father’s <input  id=5 type=Circle />.  <span style='border:2px solid black;padding:0px 5px;border-radius:5px;color:white'><input  border="none" id=11 type=Circle /></span> <br>
            </div>

  `,
    },
  },
  5: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E5",
    // audio: "Audios/1.27.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 150,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["alone", "asleep", "aware", "content", "sure"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the words below. Use
a dictionary to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;I can’t be # , but that looks like Jaime over there.<br>
            <b>2</b>&ensp;If your brother’s still # , he’s going to miss breakfast!<br>
            <b>3</b>&ensp;When I’m on holiday, I always feel # just to sit on the beach and sunbathe.<br>
            <b>4</b>&ensp;No one was # that the date of the test had been changed.<br>
            <b>5</b>&ensp;Does your sister live # or with friends?<br>
        </div>
        
            
            
          `,
        // InputRong: true,
        answer: ["sure", "asleep", "content", "aware", "alone "],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 850,
      // padding: 0,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      marginLeft: 20,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "adventure",
          "batteries",
          "easy",
          "experiences",
          "pamper",
          "physically",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Correct the underlined mistakes in the sentences. Only
use the past simple when it is the only correct answer.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

       <div style=''> 
          <b>1</b>&ensp;Most of us <u>would believe</u> in Santa Claus when we were younger.<br>#<br>
          <b>2</b>&ensp;People <u>wouldn’t be</u> so obsessed with celebrities before the internet.<br>#<br>
          <b>3</b>&ensp;<u>Would you use</u> to enjoy eating vegetables as a child?<br>#<br>
          <b>4</b>&ensp;He <u>would study</u> engineering for two years before he became an actor.<br>#<br>
          <b>5</b>&ensp;<u>Would</u> famous actors use to earn so much money in the past?<br>#<br>
          </div>
         
            
            
          `,
        answer: [
          "Most of us used to believe in Santa Claus when we were younger.",
          "People didn't use to be so obsessed with celebrities before the internet.",
          "Did you use to enjoy eating vegetables as a child?",
          "He studied engineering for two years before he became an actor.",
          "Did famous actors use to earn so much money in the past? ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "12WB-2024-U5-P44-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
