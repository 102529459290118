import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    hintBox: [
      {
        src: ["amused", "ashamed", "content", "envious", "furious", "stunned"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match (1–7) with (a–g).`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "535px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-14", "1-15", "2-9", "11-3", "12-4", "10-5", "13-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />

<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
<input id=14 type=boxMatch />
<input id=15 type=boxMatch />

            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;shout<br>
                    <b>2</b>&ensp;hold up<br>
                    <b>3</b>&ensp;go on<br>
                    <b>4</b>&ensp;listen to<br>
                    <b>5</b>&ensp;hold<br>
                    <b>6</b>&ensp;sign<br>
                    <b>7</b>&ensp;demonstrate against<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;a demonstration<br>
                    <b>b</b>&ensp;a petition<br>
                    <b>c</b>&ensp;speeches<br>
                    <b>d</b>&ensp;a rally<br>
                    <b>e</b>&ensp;inequality<br>
                    <b>f</b>&ensp;slogans<br>
                    <b>g</b>&ensp;placards<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E2",
    audio: "Audios/2.13.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      width: 170,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "famine",
          "globalisation",
          "global warming",
          "homelessness",
          "immigration",
          "unemployment",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name=2.13 color=white colorimg='#d1b4d8'></headphone> Listen to a conversation between Ewan and
Emily. Which of the things below do they mention?`,
        width: "26cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "301px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          color: "rgb(146,39,143)",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "rgb(146,39,143)",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          "famine globalisation global_warming homelessness immigration unemployment",
        ],
        answers: ["0-4", "0-2", "0-6", "0-10"],
        initialValue: [],
      },
      Layout: `
      
         <input id=0 type=Circle />
         

            
          
        `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E3",
    audio: "Audios/2.13.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 40, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.13 color=white colorimg='#d1b4d8'></headphone> Listen again. Are the sentences true (T) or false (F)?`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
              <div>
                 <b>1</b>&ensp;Emily is going on a rally this weekend.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>2</b>&ensp;Ewan signed a petition against climate change.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>3</b>&ensp;Emily went on a march against a new shopping centre.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>4</b>&ensp;Ewan doesn’t think protesting will make politicians do anything.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>5</b>&ensp;Emily thinks companies might act more responsibly if people stop buying their products.<input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 <b>6</b>&ensp;Emily thinks that protesting against something is useful. <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                 
               </div>
              
           
        </div>
        
            
            
          `,
        // InputRong: true,
        answer: ["F", "F", "F", "T", "T", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E4",
    // audio: "Audios/2.05.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E4answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Match the words below with the situations.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "21cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "disease",
          "global warming",
          "homelessness",
          "terrorism",
          "unemployment",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    // hideBtnFooter: true,
    // character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='width:25cm'>
            <b>1</b>&ensp;We interviewed Tom, who had been living on the streets for a year.#<br>
            <b>2</b>&ensp;All Americans will remember 11 September 2001, when two planes crashed into the World Trade Center.#<br>
            <b>3</b>&ensp;Ebola, which is a virus, has killed thousands of people in West Africa.#<br>
            <b>4</b>&ensp;My brother, Jack, who has got a degree, still hasn’t been able to find a job.#<br>
            <b>5</b>&ensp;The weather in the Caribbean, where there have been extremely strong hurricanes, has been the worst on record.#<br>
        </div>
          `,
        answer: [
          "homelessness",
          "terrorism",
          "disease",
          "unemployment",
          "global warming",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "25cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "censorship",
          "famine",
          "gender inequality",
          "immigration",
          "nuclear weapons",
          "racism",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the nouns below.

`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>

          <div style='width:27cm'>
           <b>1</b>&ensp;Banning controversial books is an example of  #.<br>
<b>2</b>&ensp;The British population has increased because of #
and 12% of UK residents were born outside
the country.<br>
<b>3</b>&ensp;Although there is less # today, African
Americans still find it more difficult to find jobs than
white Americans.<br>
<b>4</b>&ensp;Considering how much food we throw away in
developed countries, it’s a crime that #
continues to affect a large part of the world’s population.<br>
<b>5</b>&ensp;More and more countries have  #, and that
makes the world more dangerous, not safer.<br>
<b>6</b>&ensp;Although # has been tackled in places like
universities, there is discrimination against women in
many places.<br>
          </div>
          `,
        answer: [
          "censorship",
          "immigration",
          "racism",
          "famine",
          "nuclear weapons",
          "gender inequality",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E6",
    // audio: "Audios/1.08.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 152,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      marginLeft: "100px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hold", "suggest", "give", "live", "sign", "face"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the correct form of the verbs below
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page64/E6/1.jpg" style='height:25cm'/>
          <div style='position:absolute;top:427px;left:355px'>#</div>
          <div style='position:absolute;top:497px;left:62px'>#</div>
          <div style='position:absolute;top:568px;left:145px'>#</div>
          <div style='position:absolute;top:638px;left:307px'>#</div>
          <div style='position:absolute;top:709px;left:492px'>#</div>
          <div style='position:absolute;top:815px;left:354px'>#</div>
         
         
        </div>
        `,
        answer: ["suggested", "faces", "live", "give", "held", "sign"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P64-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page64/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
