import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Write the words in brackets in the correct order to form
mixed conditional sentences. Add a comma in the
correct place.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
            <div><b>1</b>&ensp;If (know / you’d / the answer / you’d / listened).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
          
              <div><b>2</b>&ensp;If (weren’t / I’d / have / raining / it / walked).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
          
              <div><b>3</b>&ensp;If (he / have / Lucas / older / voted / could / were).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
          
              <div><b>4</b>&ensp;If (chips / have / hungry / I’d / those / was / eaten / I).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
          
              <div><b>5</b>&ensp;If (champions / might / scored / they’d / they / be / the).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
          
              <div><b>6</b>&ensp;If (you / I / have / that / were / done / wouldn’t / I).
              <div style='margin-left:25px'>
                 If<input width=600px />. 
              </div>
             
          `,
        answer: [
          "you'd listened, you'd know the answer",
          "it weren't raining, I'd have walked",
          "Lucas were older, Lucas could have voted / he were older, he could have voted",
          "I was hungry, I'd have eaten those chips",
          "they'd scored, they might be the champions",
          "I were you, I wouldn't have done that ",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E2answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct options (a–c) to complete the mixed
conditional sentences.`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-0", "5-8", "6-4"],
        initialValue: [],
      },
      Layout: `



            <b>1</b>&ensp;If Tony the lottery, he would be rich.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>wins <br> had won <br> would win</div>
                 </div>
            <b>2</b>&ensp;Life difficult if they hadn’t invented electricity!
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>would be <br> had been <br> was</div>
                 </div>
            <b>3</b>&ensp;If you’d burned the cake, we eat it.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>can’t <br> weren’t able to <br> couldn’t</div>
                 </div>
            <b>4</b>&ensp;If Zoe were braver, she for Ed’s phone number.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>might have asked <br> might asking <br> might not ask</div>
                 </div>
            <b>5</b>&ensp;We’d have gone to school if it the weekend.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>weren’t <br> hadn’t been <br> isn’t</div>
                 </div>
            <b>6</b>&ensp;If I your advice, I’d have asked for it.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                    <div>want <br> would want <br> wanted</div>
                 </div>
            <b>7</b>&ensp;If I hadn’t seen the news, I about the accident.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=6 type=Circle /></div>
                    <div>didn’t know <br> wouldn’t know <br> hadn’t known</div>
                 </div>
           
              
        `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      marginLeft: 25,
      width: 500,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the mixed conditional sentences with up to
five words, including the correct form of the words in
brackets. Do not change the order of the words. 
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div>
                  <b>1</b>&ensp;I would be sad if (I / never / meet) you!<br>#<br>
                  <b>2</b>&ensp;If Max hadn’t run so fast, (he / not / exhausted).<br>#<br>
                  <b>3</b>&ensp;They wouldn’t be nervous about the exam if (they / study / harder).<br>#<br>
                  <b>4</b>&ensp;(you / be / poor) if you’d spent all your money yesterday.<br>#<br>
                  <b>5</b>&ensp;If Lily had used suncream on the beach, (she / burned / now)!<br>#<br>
                  <b>6</b>&ensp;I would have written to you if (I / know / address). <br>#<br>
              </div>
          `,
        answer: [
          "I had never met",
          "he wouldn't be exhausted",
          "if they had studied harder",
          "You would be poor",
          "she wouldn't be burned now",
          "I knew your address",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with one word in each gap to make
mixed conditional sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page67/E4/1.jpg" style='height:14cm'/>
          <div style='position:absolute;top:131px;left:641px'>#</div>
          <div style='position:absolute;top:162px;left:280px'><input width=179px /></div>
          <div style='position:absolute;top:196px;left:44px'><input width=179px /></div>
          <div style='position:absolute;top:231px;left:152px'><input width=179px /></div>
          <div style='position:absolute;top:299px;left:224px'><input width=179px /></div>
          <div style='position:absolute;top:368px;left:393px'><input width=179px /></div>
          
         
         
         
        </div>
        `,
        answer: [
          "have",
          "wasn't / was not / weren't / were not",
          "hadn't / had not",
          "wouldn't / would not",
          "be",
          "had",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the correct form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
          Oxfam is a world leader in delivering emergency
          relief. If it weren’t for this charity, thousands of
          children in Europe <sup>1</sup>#(go) hungry
          during World War II. Today, desperate people
          <sup>2</sup>#(not have) clean water if Oxfam hadn’t made
          this a priority during humanitarian disasters. And if it
          <sup>3</sup>#(not be) for Oxfam, the NGO ActionAid might
          not exist either; there wouldn’t be so much pressure on
          governments to provide healthcare and education and to
          combat climate change if these charities <sup>4</sup>#(not develop). 
          But Oxfam might not be so successful nowadays if
          it <sup>5</sup>#(not recognise) the importance of linking
          business with charity. Oxfam employs thousands of people
          worldwide, but it couldn’t have become the UK’s biggest
          high-street seller of second-hand books if it <sup>6</sup>#(not be) 
          also for the thousands of volunteers who give their
          time working in these important charity shops.

         
          
         
         
         
        </div>
        `,
        answer: [
          "would have gone",
          "would not have|wouldn't have",
          "had not been|hadn't been",
          "had not developed|hadn't developed",
          "had not recognised / did not recognise|hadn't recognised / didn't recognise",
          "was not / were not|wasn't / weren't",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page67/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "12WB-2024-U8-P67-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
