import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E1",
    audio: "Audios/2.20.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E1answerKey.png",
    component: Circle_Write,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 159,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<headphone name=2.20 color=white colorimg='#d1b4d8'></headphone> Listen and choose the correct answers (a–c).
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-0", "1-8", "2-4", "3-8", "4-4"],
        initialValue: [],
      },
      Layout: `






            <b>1</b>&ensp;Listen to the conversation. Why does the woman suggest going to the festival by coach?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>It’s the most convenient way to go. <br> It’s the cheapest way to go. <br> It’s the most reliable way to go.</div>
                 </div>
            <b>2</b>&ensp;Listen to the speaker. What is his intention?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>To explain how to get tickets for a festival. <br> To describe one of the best festivals in the UK. <br> To persuade people to go to a festival.</div>
                 </div>
            <b>3</b>&ensp;Listen to the interview. How does the volunteer feel about his job?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>He’s overwhelmed by the quantity of rubbish. <br> He’s proud of his group’s contribution. <br> He’s disappointed by the lack of co-operation.</div>
                 </div>
            <b>4</b>&ensp;Listen to the news report. Which is the best headline?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>GLASTONBURY WEATHER BETTER THAN EVER <br> FESTIVAL RAINED OFF YET AGAIN <br> REVELLERS SET FOR SUNSHINE AND SHOWERS</div>
                 </div>
            <b>5</b>&ensp;Listen to the conversation. What is the mother’s attitude?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>She’s worried about her son staying in a tent. <br> She’s resigned to the fact her son is going to the festival. <br> She’s annoyed that her son already has a ticket.</div>
                 </div>
            
           
              
        `,
    },
  },
  2: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E2answerKey.png",
    component: T6,
    recorder: true,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 400, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["accommodation", "clothes", "food and drink", "what to see"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Work in pairs. A British exchange student is coming to stay
with you when an important festival is on and has asked
what happens during the event. Talk with him/her about
the topics below.
`,
        color: "black",
        left: 50,
        width: "27cm",
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
             
          `,
        answer: [
          "I had never met",
          "he wouldn't be exhausted",
          "if they had studied harder",
          "You would be poor",
          "she wouldn't be burned now",
          "I knew your address",
        ],
      },
    ],
  },
  3: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E3",
    // audio: "Audios/2.06.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      marginTop: 10,
      // fontWeight: "bold",
      marginLeft: 25,
      width: 500,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "cruel",
          "judgemental",
          "spontaneous",
          "stingy",
          "stubborn",
          "vain",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the article. Match sentences (A–G) with gaps (1–5)
in the article. There are two extra sentences.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:24cm'>
              <div>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>A</b>&ensp;But others have continued, like the role of women as the head of the family and the community. <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>B</b>&ensp;Then, there’s a website for children where they can learn more about Chickasaw history, people, culture and language through interactive games and activities. <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>C</b>&ensp;The use of new technologies and dynamic business strategies in a global market are also encouraged. <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>D</b>&ensp;She was the last remaining monolingual speaker of the Chickasaw language. <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>E</b>&ensp;The study of the arts and humanities is necessary to the success of the Chickasaw Nation.  <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>F</b>&ensp;And if history is anything to go by, he isn’t wrong. <br>
                 <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><b>G</b>&ensp;This led to the number of native speakers falling from around 3,000 in the 1960s to fewer than seventy today. <br>
                 
                 </div>
              <div style='margin-top:20px'>
                    Monday 30 December 2013 was a tragic
                      day for the people of the Chickasaw Nation.
                      It was the day on which a 93-year-old
                      member of the tribe called Emily Johnson
                      Dickerson passed away. But Emily wasn’t
                      just any old member of the tribe. 1
                      Through her death, the tribe lost not only a
                      beloved member of their extended family,
                      but also a valuable source of knowledge
                      about their language and culture.<br>
                      The Chickasaw Nation is made up of
                      around 57,000 people, most of whom
                      live in the thirteen areas of Oklahoma,
                      USA, that were once designated ‘Indian
                      Territory’. Their land is largely rural, but
                      much of their way of life is modern – they
                 </div>
           
        </div>
          `,
        InputRong: true,
        answer: ["2", "4", "", "1", "", "5", "3"],
      },
    ],
  },
  4: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E4",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginTop: 20,
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with one word in each gap to make
mixed conditional sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: {
      fontSize: 23,
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 166,
      padding: 0,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "commercialised",
          "crowded",
          "dull",
          "inaccessible",
          "run-down",
          "unspoilt",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page74/E4/1.jpg" style='height:14cm'/>
          <div style='position:absolute;top:129px;left:641px'>#</div>
          <div style='position:absolute;top:162px;left:280px'><input width=179px /></div>
          <div style='position:absolute;top:196px;left:44px'><input width=179px /></div>
          <div style='position:absolute;top:231px;left:152px'><input width=179px /></div>
          <div style='position:absolute;top:299px;left:224px'><input width=179px /></div>
          <div style='position:absolute;top:368px;left:393px'><input width=179px /></div>
          
         
         
         
        </div>
        `,
        answer: ["have", "wasn't / weren't", "hadn't", "wouldn't", "be", "had"],
      },
    ],
  },
  5: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E5",
    // audio: "Audios/2.02.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,

      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 450,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "23cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["hard", "open", "quick", "self", "thick", "well"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the correct form of
the verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    character: /[|/]/,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `

        <div style='width:26cm'>
          Oxfam is a world leader in delivering emergency
          relief. If it weren’t for this charity, thousands of
          children in Europe <sup>1</sup>#(go) hungry
          during World War II. Today, desperate people
          <sup>2</sup>#(not have) clean water if Oxfam hadn’t made
          this a priority during humanitarian disasters. And if it
          <sup>3</sup>#(not be) for Oxfam, the NGO ActionAid might
          not exist either; there wouldn’t be so much pressure on
          governments to provide healthcare and education and to
          combat climate change if these charities <sup>4</sup>#(not develop). 
          But Oxfam might not be so successful nowadays if
          it <sup>5</sup>#(not recognise) the importance of linking
          business with charity. Oxfam employs thousands of people
          worldwide, but it couldn’t have become the UK’s biggest
          high-street seller of second-hand books if it <sup>6</sup>#(not be) 
          also for the thousands of volunteers who give their
          time working in these important charity shops.

         
          
         
         
         
        </div>
        `,
        answer: [
          "would have gone",
          "would not have|wouldn't have",
          "had not been|hadn't been",
          "had not developed|hadn't developed",
          "had not recognised / did not recognise|hadn't recognised / didn't recognise",
          "was not / were not|wasn't / weren't",
        ],
      },
    ],
  },
  6: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 176,
      // padding: 0,
      paddingTop: 7,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthful"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with one word in each gap.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div style='position:relative'>
          <img src="img/FriendsPlus/Page74/E6/1.jpg" style='height:15cm'/>
          <div style='position:absolute;top:46px;left:318px'>#</div>
          <div style='position:absolute;top:152px;left:99px'>#</div>
          <div style='position:absolute;top:223px;left:250px'>#</div>
          <div style='position:absolute;top:293px;left:210px'>#</div>
          <div style='position:absolute;top:364px;left:247px'>#</div>
          <div style='position:absolute;top:435px;left:266px'>#</div>
         
         
         
        </div>
        `,
        answer: ["it", "One / The", "what", "One", "all", "one"],
      },
    ],
  },
  7: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page74/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Cumulative Review 2",
    id: "12WB-2024-CR2-P74-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page72/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
