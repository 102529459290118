import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 140, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct answers (a–c) to complete the sentences.
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ["0-8", "1-0", "2-4", "3-8", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `

            <b>1</b>&ensp;It looks as though that shop has closed – it’s been empty for weeks now.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=0 type=Circle /></div>
                    <div>for now <br> for one thing <br> for good</div>
                 </div>
            <b>2</b>&ensp;The instructions should be clear, but if you’re , just ask someone.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=1 type=Circle /></div>
                    <div>in doubt <br> in vain <br> in effect</div>
                 </div>
            <b>3</b>&ensp;It was a horrible journey, but we got there .
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=2 type=Circle /></div>
                    <div>in all <br> in the end <br> in that case</div>
                 </div>
            <b>4</b>&ensp;If the staff here think you’re , they’ll ask to see some identification.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=3 type=Circle /></div>
                    <div>under arrest <br> under the impression <br> under age</div>
                 </div>
            <b>5</b>&ensp;There’s too much to risk failing this exam.
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=4 type=Circle /></div>
                    <div>at stake <br> at fault <br> at the moment</div>
                 </div>
            <b>6</b>&ensp;Do you have more with your father or your mother?
                 <div style='display:flex;'>
                    <div style='margin-left:20px;margin-right:10px'><input id=5 type=Circle /></div>
                    <div>in any case <br> in common <br> in that case</div>
                 </div>
              
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 800, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["drive", "go", "live", "lose", "make"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Write the words in brackets in the correct order to
complete the sentences.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


         <div style='width:25cm'>
         <div style='display:flex'> 
            <b>1</b> 
              <div style='margin-left:15px;width:20cm'>
                We were (the / impression / party / the / under / that) had been cancelled.<br>#
              </div>
          </div>
          <div style='display:flex'>
              <b>2</b> 
              <div style='margin-left:15px;width:20cm'>
                Dan and Mia (a / have / for / been / while / dating).<br>#
              </div>
          </div>
          <div style='display:flex'>
              <b>3</b> 
              <div style='margin-left:15px;width:20cm'>
                I’m afraid (a / moment / I’m / busy / the / bit / at). Can I call you back?<br>#
              </div>
          </div>
          <div style='display:flex'>
              <b>4</b> 
              <div style='margin-left:15px;width:20cm'>
                I was going to buy the brown boots, (on / decided / second / but / thoughts / I) to get the black ones.<br>#
              </div>
          </div>
          <div style='display:flex'>
              <b>5</b> 
              <div style='margin-left:15px;width:20cm'>
                ‘What do you like about living in Barcelona?’ ‘Well, (one / a / got / for / thing / it’s) great nightlife.’<br>#
              </div>
          </div>
         
          
        </div>
          `,
        answer: [
          "under the impression that the party",
          "have been dating for a while",
          "I'm a bit busy at the moment",
          "but on second thoughts I decided",
          "for one thing it's got a",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // marginLeft: 20,
      background: "none",
      width: 60,
      paddingTop: 7,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct prepositions
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `

          
                <div>
                  <b>1</b>&ensp;The ticket office sold me a return rather than a single # mistake.<br>
                  <b>2</b>&ensp;Do you think Petra didn’t invite us to her party # purpose?<br>
                  <b>3</b>&ensp;I heard Finn got # trouble for not doing his homework.<br>
                  <b>4</b>&ensp;Tina’s not very keen on rock music – # fact, she can’t stand it!<br>
                  <b>5</b>&ensp;I’d like to see you later, but # course, if you’re busy, that’s fine.<br>
                </div>
                
          
          
          `,
        answer: ["by", "on", "in", "in", "of"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Correct the mistakes in the phrases in bold. Tick the
correct sentences.
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [
          "by the time",
          "",
          "in the end ",
          "at the end ",
          "at the time",
          "",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "5-0"],
        initialValue: [],
      },
      Layout: `

            <div style='line-height:50px;width:32cm'>
              <b>1</b>&ensp;It was a long day and <b>at the time</b> I got home I was exhausted. # <input id=0 type=Circle /> <br>
            <b>2</b>&ensp;The show doesn’t start until eight, so we should still get there <b>in time</b>. # <input id=1 type=Circle /> <br>
            <b>3</b>&ensp;Joe and Lisa were always arguing and <b>at the end</b> they split up. # <input id=2 type=Circle /> <br>
            <b>4</b>&ensp;It’s only <b>by the end</b> of the book that you learn the killer’s identity. # <input id=3 type=Circle /> <br>
            <b>5</b>&ensp;I didn’t realise it <b>by the time</b>, but the girl we met last night was Terry’s sister. # <input id=4 type=Circle /> <br>
            <b>6</b>&ensp;We should have moved house <b>by the end</b> of the month. # <input id=5 type=Circle /> <br>
            </div>

  `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 658, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct words to complete the text.  
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a_while / one_thing",
          "effect / common",
          "fault / last",
          "vain / control",
          "time / doubt",
          "control / the_impression",
          "time / fact",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-4", "5-4", "6-0"],
        initialValue: [],
      },
      Layout: `
            <div style='width:26cm'>
              How do you stop being friends with
              someone you no longer feel close to?
              If you’ve been friends for <sup>1</sup><input id=0 type=Circle />, you may simply not have
              anything in <sup>2</sup><input id=1 type=Circle /> any
              more. It doesn’t mean anyone’s at
              <sup>3</sup><input id=2 type=Circle />. If you’ve tried in <sup>4</sup><input id=3 type=Circle /> to make things work, maybe it’s
              best to end the friendship. Naturally, you don’t want to hurt the
              other person, and if you’re in any <sup>5</sup><input id=4 type=Circle />, don’t say or do
              anything you might regret. Perhaps your friend doesn’t realise
              how you feel – they may be under <sup>6</sup><input id=5 type=Circle /> 
              that everything’s fine. You could talk to them and suggest not
              seeing each other for a few weeks, and in <sup>7</sup><input id=6 type=Circle /> you’ll know
              whether you want your friendship to continue or not.
            </div>
              
        `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box.  Complete the sentences with
appropriate nouns and your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page20/E6/1.jpg" style='height:7cm'/>
        <div style='width:26cm'>
            <b>1</b>&ensp;Although I haven’t done it for a #, I used to like #.<br>
            <b>2</b>&ensp;At the #, my favourite song is #.<br>
            <b>3</b>&ensp;The last time I was late for something was #. I couldn’t get there on # because #.<br>
            <b>4</b>&ensp;In the future, I’d love to #. With any #, I’ll be able to.<br>
        </div>
          `,
        answer: ["while", "", "moment", "", "", "time", "", "", "luck"],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P20-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page20/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
