import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    component: T6,
    // textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { marginLeft: 20, width: 1000, paddingTop: 7 },
    hintBox: [
      {
        src: ["close", "common", "eye", "sorry", "wary", "wavelength"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the task above. How many elements are there to cover?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <img src="img/FriendsPlus/Page15/E1/1.jpg" style='height:6cm'/>
        <textarea id=0 rows=5></textarea>
          
          
          `,
        answer: [
          `Three elements
            1 Give your opinion of the view
            2 Describe how we make friends these days
            3 List situations where you can recognise real friends|Three elements: 
            1 Give your opinion of the view
            2 Describe how we make friends these days
            3 List situations where you can recognise real friends`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 40, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: [
          "get over",
          "not make up",
          "not turn up",
          "put through",
          "talk into",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["about", "at", "by", "of", "to", "with"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the essay. In which paragraphs does the writer cover
each element of the task?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display:flex;justify-content:space-between'>
            <span><b>Element 1</b>:#</span>
            <span><b>Element 2</b>:#</span>
            <span><b>Element 3</b>:#</span>
          </div>

        <div style='margin-top:20px;width:25cm'>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>A</span>&ensp;I find that the saying ‘A friend in need is a friend indeed’
              is a true one and I wholeheartedly agree with it. I do
              think that a true friend is one who is always there for you,
              no matter what.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>B</span>&ensp;In the internet age, people are more sociable than ever. It’s
            easy to make lots of friends online because no one makes
            any demands on you. Not that it isn’t good to have a wide
            circle of people to socialise with. Nevertheless, it is said
            that most of us can count our number of true friends on
            the fingers of one hand, and I personally think that it is in
            times of trouble that you find out who your real friends are.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>C</span>&ensp;I have known my closest friend Mina since primary school
            and she remains the nicest, gentlest person I know. We
            rarely have arguments. Not that we agree on everything,
            but we generally have a caring and supportive relationship.
            When my mum was very ill in hospital, she was the truest
            friend of all. She never once complained that I was bad
            company, like one or two of my other friends, and I could
            always talk to her about all my fears. This type of situation
            really shows you who your friends are.<br>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>D</span>&ensp;So, in my view, although you may have lots of friends
            to have fun with, make sure you have at least one close
            friend who can support you when you need it most.<br>

        </div>
        
          
          `,
        answer: ["A", "B", "C"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Read the <span style='color:#2385C6'>Reading Strategy</span> and underline an example of
each of the structures below in the essay`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          // color: "red",
          borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [
          `
          I_find_that_the_saying_‘A_friend_in_need_is_a_friend_indeed’_is_a_true_one_and_I_wholeheartedly_agree_with_it|. 
          I_do_think_that_a_true_friend_is_one_who_is_always_there_for_you,_no_matter_what|.<br>
        `,
          `In_the_internet_age,_people_are_more_sociable_than_ever|.  It’s_easy_to_make_lots_of_friends_online_because_no_one_makes_any_demands_on_you|. Not_that_it_isn’t_good_to_have_a_wide_circle_of_people_to_socialise_with|. Nevertheless, it_is_said_that_most_of_us_can_count_our_number_of_true_friends_on_the_fingers_of_one_hand,_and_I_personally_think_that_it_is_in_times_of_trouble_that_you_find_out_who_your_real_friends_are|.<br>`,
          `I_have_known_my_closest_friend_Mina_since_primary_school_and_she_remains_the_nicest,_gentlest_person_I_know|. We_rarely_have_arguments|. Not_that_we_agree_on_everything,_but_we_generally_have_a_caring_and_supportive_relationship|. When_my_mum_was_very_ill_in_hospital,_she_was_the_truest_friend_of_all|. She_never_once_complained_that_I_was_bad company,_like_one_or_two_of_my_other_friends,_and_I_could always_talk_to_her_about_all_my_fears|. This_type_of_situation really_shows_you_who_your_friends_are|.<br>`,
          `So,_in_my_view,_although_you_may_have_lots_of_friends_to_have_fun_with,_make_sure_you_have_at_least_one_close_friend_who_can_support_you_when_you_need_it_most|.<br>`,
        ],
        answers: ["1-10", "1-14", "1-16", "0-48", "2-8", "3-0"],
        initialValue: [],
      },
      Layout: `


        <b>1</b>&ensp;contrasting clauses with <i>but</i> and <i>although</i><br>
        <b>2</b>&ensp;an example of another linker<br>
        <b>3</b>&ensp;an example of preparatory <i>it</i><br>
        <b>4</b>&ensp;a sentence starting with <i>Not that</i> …<br>
        <b>5</b>&ensp;an example of <i>do/did</i> for emphasis<br>

        <div style='margin-top:20px;width:25cm'>
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>A</span>&ensp;<input id=0 type=Circle />
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>B</span>&ensp;<input id=1 type=Circle />
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>C</span>&ensp;<input id=2 type=Circle />
          <span style='border:2px solid;border-radius:5px;padding:0px 5px'>D</span>&ensp;<input id=3 type=Circle />

        </div>
        `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the second sentence so that it means the same
as the first.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `




         <div style='width:25cm'>
         <div style='display:flex'> 
            <b>1</b> 
              <div style='margin-left:15px'>
                She’s a good friend, but she can be a bit moody.<br>
                Although <input width=635px >.
              </div>
          </div>
          <div style='display:flex'>
              <b>2</b> 
              <div style='margin-left:15px'>
                People say that you must be a good friend to have a good friend.<br>
                It <input width=722px >.
              </div>
          </div>
          <div style='display:flex'>
              <b>3</b> 
              <div style='margin-left:15px'>
                I tried talking to him, but he didn’t understand what I was saying.<br>
                I tried talking to him, not that <input width=413px >.
              </div>
          </div>
          <div style='display:flex'>
              <b>4</b> 
              <div style='margin-left:15px'>
                I try hard, but she won’t be friends with me.<br>
                I try hard. However,<input width=526px >.
              </div>
          </div>
          
          
        </div>
          `,
        answer: [
          "she's a good friend, she can be a bit moody|she is a good friend, she can be a bit moody",
          "is said that you must be a good friend to have a good friend",
          "he understood what I was saying",
          "she won't be friends with me|she will not be friends with me",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E5answerKey.png",
    component: T6,
    // textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { marginLeft: 20, width: 700, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the sentences using <i>do/did</i> for emphasis.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
         <div style='width:25cm'>

            <b>1</b>&ensp;I liked her.<br>#<br>
            <b>2</b>&ensp;‘Why didn’t you tell him I was here?’ ‘I told him!’<br><input width=440px  /><input margin-left=0px width=210px /><br>
            <b>3</b>&ensp;Although she’s twice my age, we have a lot in common.<br>#<br>
            <b>4</b>&ensp;I think he is a good friend.<br>#<br>
          
         
          
        </div>
          `,
        answer: [
          "I did like her.",
          "Why didn't you tell him I was here?",
          "I did tell him!",
          "Although she's twice my age, we do have a lot in common.|Although she is twice my age, we do have a lot in common.",
          "I do think he is a good friend.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E6answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 100,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the task above. Make some notes for your opinion and personal description.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
          
          <img src="img/FriendsPlus/Page15/E6/1.jpg" style='height:4cm'/>
          <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: ["a", "a", "few", "Both", "many", "The", "of"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "12WB-2024-U1-P15-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page15/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
