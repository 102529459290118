import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E1",
    exerciseKey: "img/FriendsPlus/Page2/Key/E1answerKey.png",
    component: T6,
    // textAlign:'center',
    stylesTextInput: { width: 600, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "disguise",
          "exaggerate",
          "lie",
          "influence",
          "pass",
          "photoshop",
        ],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Change the direct speech into reported speech.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div>
                <b>1</b>&ensp;‘You must take these tablets twice a day.’<br>
                The doctor says I<input id=0 width='498px'></input>.<br>
                <b>2</b>&ensp;‘I’m going to the gym tomorrow.’<br>
                Ben told me<input id=1 width='550px'></input>.<br>
                <b>3</b>&ensp;‘You don’t have to wait for me.’<br>
                Liz said that I<input id=2 width='540px'></input>.<br>
                <b>4</b>&ensp;‘I took my final exam yesterday.’<br>
                Jack told me<input id=3 width='549px'></input>.<br>
                <b>5</b>&ensp;‘I haven’t told you the complete truth.’<br>
                Pablo said<input id=4 width='569px'></input>.<br>
                <b>6</b>&ensp;‘I’ll meet you later today.’<br>
                Jim tells me<input id=5 width='555px'></input>.<br>
                <b>7</b>&ensp;‘I prefer this café to the one opposite.’<br>
                Julia says<input id=6 width='583px'></input>.<br>
                <b>8</b>&ensp;‘I’ll always remember meeting you.’<br>
                Ann told me<input id=7 width='547px'></input>.<br>
            </div>
        `,
        answer: [
          "must take these tablets twice a day",
          "that he was going to the gym the next day",
          "didn't have to wait for her",
          "that he had taken his final exam the day before",
          "that he hadn't told me the complete truth",
          "that he'll meet me later today",
          "that she prefers this café to the one opposite",
          "that she would always remember meeting me",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E2",
    exerciseKey: "img/FriendsPlus/Page2/Key/E2answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 350, paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the 
          words in brackets. Do not change the order of the words. 
          Use a maximum of five words including the words in 
          brackets.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;Will asked us#(time / game / start) the next day. <br>
            <b>2</b>&ensp;The assistant asked him if#(he / like / try) on a smaller jacket.<br>
            <b>3</b>&ensp;My teacher asked me#(I / finish) the exercise and I said I hadn’t.<br>
            <b>4</b>&ensp;I asked Maria#(which / film / seen) the night before.<br>
            <b>5</b>&ensp;The dentist asked#(me / why) never used an electric toothbrush before.<br>
            <b>6</b>&ensp;She asked him if#(he / going) the park and he said he wasn’t.<br>
            <b>7</b>&ensp;I asked the policeman whether#(he / help / us) and he said he could.<br>
        </div>
        
        `,
        answer: [
          "what time the game started",
          "he would like to try",
          "if I had finished",
          "which film she had seen",
          "me why I had",
          "he was going to",
          "he could help us",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E3",
    exerciseKey: "img/FriendsPlus/Page2/Key/E3answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 650, paddingTop: 3 },
    hintBox: [
      {
        src: ["cheat", "lie", "disseminate", "mislead", "swear"],
        width: 600,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Change the direct speech into reported speech. Begin 
          with <i>My mum asked me.</i>`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;‘Why are you home so late?’<br>#<br>
            <b>2</b>&ensp;‘Did you leave school on time today?’<br>#<br>
            <b>3</b>&ensp;‘When are you going to tidy your room?’<br>#<br>
            <b>4</b>&ensp;‘Have you seen your brother this afternoon?’<br>#<br>
            <b>5</b>&ensp;‘Will you help me with the shopping tomorrow?’<br>#<br>
            <b>6</b>&ensp;‘Would you like me to give you some money for a pizza?’<br>#<br>
        </div>
        
        `,
        answer: [
          "My mum asked me why I was home so late.",
          "if I had left school on time that day.",
          "when I was going to tidy my room.",
          "if I had seen my brother that afternoon.",
          "if I’d help her with the shopping the next day.",
          "if I'd like her to give me some money for a pizza.",
        ],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E4",
    exerciseKey: "img/FriendsPlus/Page2/Key/E4answerKey.png",
    textareaStyle: {
      width: 774,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Report the dialogue using say or ask in the past simple.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 100, paddingTop: 3 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#92278f",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page2/E4/1.jpg" style='height:9cm'/>
        <div style='display:flex'>
            <div>
                <b>Dan</b> <br>
                <b>Zoe</b> <br>
                <b>Dan</b> <br>
                <b>Zoe</b> <br>
                <b>Dan</b> <br>
                <b>Zoe</b> <br>
                <b>Dan</b> <br>
                <b>Zoe</b> <br>
            </div>
             <div style='margin-left:20px'>
                Are you going to the concert tomorrow evening?<br>
                No, I’m not. I haven’t got any money.<br>
                I’ll lend you some. How much do you need?<br>
                That’s really kind of you, but I have to finish my homework too.<br>
                Would you like me to help you finish it?<br>
                Do you speak German? <br>
                No, I don’t. Your cousin does, doesn’t she?<br>
                Yes, she does. Perhaps I should ask her.<br>
             </div>
        </div>
        <textarea id=0 rows=8></textarea>
        `,
        answer: [
          `Dan asked Zoe if she was going to the concert the next evening. Zoe said she wasn't. She said she didn't have any money. Dan said he'd lend her some. He asked her how much she needed. Zoe said that was really kind of him, but she had to finish her homework too. Dan asked her if she'd like him to help her finish it. Zoe asked him if he spoke German. Dan said he didn't. He asked her if her cousin did. Zoe said she did. She said perhaps she should ask her.`,
        ],
      },
    ],
  },
  5: {
    unit: "Starter",
    id: "12WB-2024-Starter-P8-E5",
    exerciseKey: "img/FriendsPlus/Page2/Key/E5answerKey.png",
    component: T6,
    // textAlign:'center',
    stylesTextInput: { width: 700, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthfu"],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Write a second sentence that has a similar meaning to the 
          first. Use the words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:25cm'>
            <b>1</b>&ensp;‘Do you know the answer to these questions?’ Sally asked me. (if)<br>#<br>
            <b>2</b>&ensp;‘I mustn’t be late for the trip tomorrow,’ said Bella. (next)<br>#<br>
            <b>3</b>&ensp;‘Have you heard the band’s new album yet?’ asked Kostas. (me)<br>#<br>
            <b>4</b>&ensp;‘I won’t be at school next week,’ said Mark. (says)<br>#<br>
            <b>5</b>&ensp;‘My plane arrives at ten in the morning,’ said Andrew. (tells)<br>#<br>
        </div>`,
        answer: [
          "Sally asked me if I knew the answer to the / those questions.|Sally asked me if I knew the answer to the questions.|Sally asked me if I knew the answer to those questions.",
          "Bella said that she mustn't be late for the trip the next day.",
          "Kostas asked me if I'd heard the band's new album yet.",
          "Mark says that he won't be at school next week.",
          "Andrew tells me that his plane arrives at ten in the morning.",
        ],
      },
    ],
  },
};

export default json;
