import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P61-E1",
    exerciseKey: "img/FriendsPlus/Page61/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength:1,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 190, borderBottom: "dash", background: "none" },
    hintBox: [
      {
        src: [
          "find out",
          "hole up",
          "let on",
          "make out",
          "play on",
          "set up",
          "take in",
          "zoom in on",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Replace the underlined words with the correct form
of the phrasal verbs.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <hintbox id='0'></hintbox>
            <div>
              <b>1</b>&ensp;I can’t believe you were <u>deceived</u> by his tricks! #<br>
              <b>2</b>&ensp;The criminals <u>established</u> false bank accounts. #<br>
              <b>3</b>&ensp;I hate advertising that <u>takes advantage of</u> our fears.#<br>
              <b>4</b>&ensp;Mark <u>told us</u> that he was planning a surprise for Anna. #<br>
              <b>5</b>&ensp;The police <u>discovered</u> what the criminals were planning. #<br>
              <b>6</b>&ensp;The media <u>paid special attention to</u> the government’s military plans. #<br>
              <b>7</b>&ensp;He <u>claimed</u> that he was rich, but it wasn’t true. #<br>
              <b>8</b>&ensp;The bank robbers were <u>hiding</u> in a friend’s house. #<br>
            </div>
        `,
        answer: [
          "taken in",
          "set up",
          "plays on",
          "let on",
          "found out",
          "zoomed in on",
          "made out",
          "holed up",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P61-E2",
    exerciseKey: "img/FriendsPlus/Page61/Key/E2answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 800, background: "none", paddingTop: 3 },
    hintBox: [
      {
        src: [
          "belief",
          "difference",
          "evidence",
          "increase",
          "interest",
          "need",
          "obsession",
          "reason",
        ],
        width: 1000,
        colorHint: "#92278f",
        // borderColor:"red",
        marginLeft: -50,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What does the real story behind <i>The War 
          of the Worlds</i> show us?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page61/E2/1.jpg" style='height:16.5cm'/>
        <div>
          <b>Answer:</b>#
        </div>
       
        `,
        answer: [
          "That the media can invent stories and maintain them for a long time.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P61-E3",
    exerciseKey: "img/FriendsPlus/Page61/Key/E3answerKey.png",
    component: Circle_Write,

    // hideBtnFooter:true,
    titleQuestion: [
      {
        num: "3",
        title: ` Read the <span style='color:#42429c'>Reading Strategy</span> and the text again and choose 
          the correct answers (a–d).`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-4", "1-0", "2-12", "3-8", "4-12"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page61/E3/1.jpg" style='height:10.5cm'/><br>

      <b>1</b>&ensp;The announcer on the radio play in 1934
       <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=0 type='Circle' /></div>
          <div>
                said American farmers were being attacked.<br>
                suggested that what he was saying was difficult to believe.<br>
                explained that America was invading Mars.<br>
                was suffering from a panic attack.<br>
          </div>
       </div>
      <b>2</b>&ensp;HG Wells’s famous novel
       <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=1 type='Circle' /></div>
          <div>
                was adapted for the radio.<br>
                deceived millions of people.<br>
                became famous in 1934.<br>
                was about media manipulation.<br>
          </div>
       </div>
      <b>3</b>&ensp;Newspapers didn’t like the radio because
       <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=2 type='Circle' /></div>
          <div>
                it was more exciting.<br>
                it controlled people.<br>
                it worried people.<br>
                it was a rival for income.<br>
          </div>
       </div>
      <b>4</b>&ensp;The stories in the newspapers about the play
       <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=3 type='Circle' /></div>
          <div>
                were based on information from another radio station.<br>
                have been forgotten about today.<br>
                are only being questioned today.<br>
                explained what really happened.<br>
          </div>
       </div>
      <b>5</b>&ensp;The writer wants to show that
       <div style='display:flex'>
          <div style='margin-left:20px;margin-right:5px'><input id=4 type='Circle' /></div>
          <div>
              people can be   made to believe that they are being attacked by aliens.<br>
                there wasn’t enough advertising for both the radio and newspapers in the 1930s.<br>
                the radio and newspapers are enemies.<br>
                the media can create a legend that is not true.<br>
          </div>
       </div>
      
      `,
    },
  },

  5: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P61-E5",
    exerciseKey: "img/FriendsPlus/Page61/Key/E5answerKey.png",
    component: T6,
    stylesTextInput: { width: 850 },
    titleQuestion: [
      {
        num: "5",
        title: `Use a dictionary and correct the mistakes with prepositions 
          in the sentences. Tick the two sentences that do not 
          contain any mistakes.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          <b>1</b>&ensp;No one expected the band to rise in fame so quickly. <br>#<br>
          <b>2</b>&ensp;My cousin’s gap year gave her a real taste for travel. <br>#<br>
          <b>3</b>&ensp;Bill and his girlfriend have had a difference in opinion and aren’t speaking. <br>#<br>
          <b>4</b>&ensp;You look very pale – is something the matter of you? <br>#<br>
          <b>5</b>&ensp;Customers have recently been experiencing difficulty in using this website. <br>#<br>
          <b>6</b>&ensp;There’s no point of getting upset about it. <br>#<br>
        </div>`,
        answer: [
          "No one expected the band to rise to fame so quickly.",
          "",
          "Bill and his girlfriend have had a difference of opinion and  aren't speaking.",
          "You look very pale - is something the  matter with you?",
          "",
          "There's no point in getting upset  about it.",
        ],
        InputRong: true,
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "12WB-WB-U7-P61-E6",
    exerciseKey: "img/FriendsPlus/Page61/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: { width: 950, paddingTop: 3 },
    hintBox: [
      {
        src: ["neutral", "fake", "straight", "trustworthy", "truthfu"],
        width: 800,
        colorHint: "#92278f",
        borderColor: "none",
        marginLeft: -80,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with the correct preposition and 
          your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <b>1</b>&ensp;I’ve never understood the point. #<br>
          <b>2</b>&ensp;Where I live, there’s been a rise. #<br>
          <b>3</b>&ensp;Most of my friends have an interest. #<br>
          <b>4</b>&ensp;I wish we could find a solution.#<br>
          <b>5</b>&ensp;I’d love to prove the existence. #<br>
          <b>6</b>&ensp;Nowadays, there’s very little demand. #<br>
        </div>
          `,
        answer: [
          "Greg apologised to Katya for not going to her barbecue the day before. Katya admitted to Greg that she'd been quite upset about it. Greg insisted that it hadn't been his fault. Katya refused to listen to any more of hislies. Greg explained that he hadn't been able to go because he'd broken his wrist and had had to go to hospital. Katya suggested that he go over that afternoon. Greg promised that he'd definitely be there that time. Katya reminded him to take her birthday present with him.",
        ],
      },
    ],
  },
};

export default json;
