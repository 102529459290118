import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    hintBox: [
      {
        src: ["amused", "ashamed", "content", "envious", "furious", "stunned"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the illnesses and injuries with the treatments.`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [
          "0-16",
          "1-9",
          "12-2",
          "10-3",
          "14-4",
          "11-5",
          "13-6",
          "15-7",
        ],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />

<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
<input id=14 type=boxMatch />
<input id=15 type=boxMatch />
<input id=16 type=boxMatch />
            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;virus<br>
                    <b>2</b>&ensp;chest infection<br>
                    <b>3</b>&ensp;heartburn<br>
                    <b>4</b>&ensp;sore throat<br>
                    <b>5</b>&ensp;sprain<br>
                    <b>6</b>&ensp;fracture<br>
                    <b>7</b>&ensp;cut<br>
                    <b>8</b>&ensp;bee sting<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;antibiotics<br>
                    <b>b</b>&ensp;throat sweets / painkillers<br>
                    <b>c</b>&ensp;X-ray<br>
                    <b>d</b>&ensp;antacids<br>
                    <b>e</b>&ensp;antiseptic cream<br>
                    <b>f</b>&ensp;bandage / anti-inflammatories<br>
                    <b>g</b>&ensp;antihistamine<br>
                    <b>h</b>&ensp;rest and lots of liquids<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      width: 170,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "could repeat that?",
          "like … ?",
          "mean by … ?",
          "quite catch that.",
          "same as … ?",
          "saying that again?",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#009346'>Speaking Strategy</span> and complete the sentences
with the phrases below.`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page54/E2/1.jpg" style='height:5.5cm'/><br>
        <b>1</b>&ensp;Sorry, do you think you<input width=300px /><br>
        <b>2</b>&ensp;I’m afraid I didn’t<input width=371px /><br>
        <b>3</b>&ensp;Would you mind<input width=375px /><br>
        <b>4</b>&ensp;What do you<input width=416px /><br>
        <b>5</b>&ensp;Is that something<input width=364px /><br>
        <b>6</b>&ensp;Is that the<input width=446px /><br>
         
          
          `,
        answer: [
          "could repeat that?",
          "quite catch that.",
          "saying that again?",
          "mean by … ?",
          "like …?",
          "same as …?",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E3",
    audio: "Audios/2.05.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 40, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name=2.05 color=white colorimg='#d1b4d8'></headphone> Listen to a student doing the task below. Which
two sentences from exercise 2 did the student use?`,
        width: "27cm",
        color: "black",
        left: -30,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 2,
        listWords: [
          "the_injury <br> how_you_did_it <br> X-ray_and_treatment <br> a_follow-up_visit",
        ],
        answers: ["0-4", "0-12"],
        initialValue: [],
      },
      Layout: `

        <div style='position:relative'>
           <img src="img/FriendsPlus/Page54/E3/1.jpg" style='height:16cm'/>
          <div style='position:absolute;top:20px;left:54px;width:186mm;line-height:38px;font-size:24px'>
            While on holiday in the UK, you go and see the doctor
            because you have hurt your ankle. Have a conversation
            with him/her and discuss the following points:<br>
            <div style='display:flex'>
              <div>
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div>
                <input id=0 type=Circle />
              </div>
            </div>
          </div>
        </div>
              
        `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E4",
    audio: "Audios/2.05.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E4answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name=2.05 color=white colorimg='#d1b4d8'></headphone> Listen again. Make notes on the following topics.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "21cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bitter",
          "disappointed",
          "disillusioned",
          "exasperated",
          "hysterical",
          "stressed",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    // hideBtnFooter: true,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='width:25cm'>
            The injury<textarea id=0 rows=2 ></textarea>
            How the person did it<textarea id=1 rows=2 ></textarea>
            Treatment<textarea id=2 rows=2 ></textarea>
            Follow-up visit<textarea id=3 rows=2 ></textarea>
            What the student needed clarification on<textarea id=4 rows=2 ></textarea>
        </div>
          `,
        answer: [
          "foot hurts at the top of the foot, it is swollen and bruised",
          "dropped a box on the foot",
          "keeping foot up, bandaging it, putting ice on it, take some anti-inflammatories",
          "in a week's time",
          "the meaning of 'fractured', what to put on his foot to keep the swelling down",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { marginLeft: 20, width: 800, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the task. Prepare notes in answer to what the doctor
will say. Ask for clarification on one of the questions. Try
to give extra detail in your answers.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page54/E5/1.jpg" style='height:7.5cm'/>
          <div style='width:27cm'>
            <b>1</b>&ensp;What seems to be the matter?<br>#<br>
            <b>2</b>&ensp;When did the rash appear?<br>#<br>
            <b>3</b>&ensp;What do you think might have caused it?<br>#<br>
            <b>4</b>&ensp;Do you have any allergies?<br>#<br>
            <b>5</b>&ensp;Well, I’m not sure. I’m going to write you a prescription for some antihistamine tablets and cream.<br>#<br>
            <b>6</b>&ensp;If it’s still bothering you, or if you feel poorly, come back in a few days.<br>#<br>
          </div>
          `,
        answer: [
          "is in two minds",
          "is over the moon",
          "gets on his nerves",
          "is down in the dumps",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E6",
    // audio: "Audios/1.08.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Now do the task using your notes from exercise 5
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            <b>1</b>&ensp;Jack says he # about going to Sam’s party.<br>
            <b>2</b>&ensp;Ann says she # about her exam results.<br>
            <b>3</b>&ensp;The boy says that Meg’s complaining #.<br>
            <b>4</b>&ensp;Ewan # about not getting the summer job.<br>
          </div>
          `,
        answer: [
          "is in two minds",
          "is over the moon",
          "gets on his nerves",
          "is down in the dumps",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "12WB-2024-U6-P54-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
