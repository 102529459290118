import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    hintBox: [
      {
        src: ["amused", "ashamed", "content", "envious", "furious", "stunned"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the adjectives below with the sentences.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>
    <div style='width:27cm'>
            <b>1</b>&ensp;‘I’d prefer not to talk about it. I’m still in a state of shock.’#<br>
            <b>2</b>&ensp;‘That was really funny! I couldn’t stop laughing!’#<br>
            <b>3</b>&ensp;‘I’ve never been treated like this before! I shall be speaking to the manager!’#<br>
            <b>4</b>&ensp;‘Anybody could have done it. She was just lucky!’#<br>
            <b>5</b>&ensp;‘We were very pleased with our presentation.’#<br>
            <b>6</b>&ensp;‘How could I have forgotten her name? I feel so embarrassed about it!’ #<br>
          </div>
          `,
        answer: [
          "stunned",
          "amused",
          "furious",
          "envious",
          "content",
          "ashamed",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      width: 170,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["disappointed", "indecisive", "miserable", "thrilled", "upbeat"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Replace the underlined adjectives with the adjectives below.`,
        width: "26cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ";",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page16/E2/1.jpg" style='height:18cm'/>
          <div style='position:absolute;top:271px;left:345px'>#</div>
          <div style='position:absolute;top:341px;left:239px'>#</div>
          <div style='position:absolute;top:455px;left:481px'>#</div>
          <div style='position:absolute;top:525px;left:501px'>#</div>
          <div style='position:absolute;top:595px;left:481px'>#</div>
        </div>
         
          
          `,
        answer: [
          "thrilled",
          "indecisive",
          "upbeat",
          "miserable",
          "disappointed",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E3",
    // audio: "Audios/1.04.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 40, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct adjectives to complete the sentences.`,
        width: "27cm",
        color: "black",
        left: -30,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "upbeat / humiliated",
          "frustrated / anxious",
          "irritated / thrilled",
          "furious / upbeat",
          "envious / disillusioned",
          "hysterical / bitter",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `

        <div style='width:26cm'>
          <b>1</b>&ensp;We felt <input id=0 type=Circle /> after losing the match against our biggest rivals 10–0!<br>
          <b>2</b>&ensp;My mum says she feels <input id=1 type=Circle /> in her job because she can’t achieve what she wants.<br>
          <b>3</b>&ensp;I was <input id=2 type=Circle /> by Sara’s constant questions about my private life.<br>
          <b>4</b>&ensp;There was a positive, <input id=3 type=Circle /> feeling after the head teacher’s speech.<br>
          <b>5</b>&ensp;Amy is <input id=4 type=Circle /> of my new coat and she wishes that she had bought it first!<br>
          <b>6</b>&ensp;Harry has bad memories of his childhood and he is very <input id=5 type=Circle /> about it.<br>
        </div>
              
        `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Match the adjectives with the definitions.
`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 200, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bitter",
          "disappointed",
          "disillusioned",
          "exasperated",
          "hysterical",
          "stressed",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
         <div style='width:25cm'>
            <b>1</b>&ensp;Feeling irritated and angry about a situation that you can’t do anything about. #<br>
            <b>2</b>&ensp;Unhappy because something or someone wasn’t as good as you expected. #<br>
            <b>3</b>&ensp;Feeling extremely worried and nervous about something, especially something you are expected to do. #<br>
            <b>4</b>&ensp;Unable to control your feelings because of extreme fear or excitement. #<br>
            <b>5</b>&ensp;Feeling unhappy because you discover the truth about someone or something. #<br>
            <b>6</b>&ensp;Feeling or showing anger, hurt or resentment because of bad experiences or treatment.  #<br>
        </div>
          `,
        answer: [
          "exasperated",
          "disappointed",
          "stressed",
          "hysterical",
          "disillusioned",
          "bitter",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      // marginBottom: -70,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 600, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Match (1–9) with (a–i) to form idioms
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "231px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "24px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "71px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "118px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "259px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "306px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "353px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "475px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [
          "0-13",
          "1-9",
          "14-2",
          "15-3",
          "16-4",
          "10-5",
          "17-6",
          "12-7",
          "11-8",
        ],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "do_you_usually_do / are_you_usually_doing",
          "I’m_seeing / I_see",
          "We’re_learning / We_learn",
          "does_the_World_Cup_Final_start / is_the_World_Cup_Final_starting",
          "Does_your_aunt_work / Is_your_aunt_working",
          "is_always_using / always_uses",
          "Are_you_going / Do_you_go",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
         <input id=0 type=boxMatch />
         <input id=1 type=boxMatch />
<input id=2 type=boxMatch />
<input id=3 type=boxMatch />
<input id=4 type=boxMatch />
<input id=5 type=boxMatch />
<input id=6 type=boxMatch />
<input id=7 type=boxMatch />
<input id=8 type=boxMatch />
<input id=9 type=boxMatch />
<input id=10 type=boxMatch />
<input id=11 type=boxMatch />
<input id=12 type=boxMatch />
<input id=13 type=boxMatch />
<input id=14 type=boxMatch />
<input id=15 type=boxMatch />
<input id=16 type=boxMatch />
<input id=17 type=boxMatch />
            <div style='line-height:47px;display:flex'>
                  <div style='margin-right:300px'>
                    <b>1</b>&ensp;lose<br>
                    <b>2</b>&ensp;blow<br>
                    <b>3</b>&ensp;be on<br>
                    <b>4</b>&ensp;be green<br>
                    <b>5</b>&ensp;be down<br>
                    <b>6</b>&ensp;be in<br>
                    <b>7</b>&ensp;be over<br>
                    <b>8</b>&ensp;be tearing<br>
                    <b>9</b>&ensp;something gets<br>
                  </div>
                  <div>
                    <b>a</b>&ensp;your top<br>
                    <b>b</b>&ensp;two minds about something<br>
                    <b>c</b>&ensp;on your nerves<br>
                    <b>d</b>&ensp;your hair out<br>
                    <b>e</b>&ensp;face<br>
                    <b>f</b>&ensp;edge<br>
                    <b>g</b>&ensp;with envy<br>
                    <b>h</b>&ensp;in the dumps<br>
                    <b>i</b>&ensp;the moon<br>
                  </div>
            </div>
                  
          
        `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E6",
    audio: "Audios/1.08.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["compliment", "nag", "praise", "tease", "tell", "warn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name=1.08 color=white colorimg='#d1b4d8'></headphone> Listen to four dialogues. Complete the sentences
with idioms from exercise 5.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='width:25cm'>
            <b>1</b>&ensp;Jack says he # about going to Sam’s party.<br>
            <b>2</b>&ensp;Ann says she # about her exam results.<br>
            <b>3</b>&ensp;The boy says that Meg’s complaining #.<br>
            <b>4</b>&ensp;Ewan # about not getting the summer job.<br>
          </div>
          `,
        answer: [
          "is in two minds",
          "is over the moon",
          "gets on his nerves",
          "is down in the dumps",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P16-E7",
    audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page16/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Complete the text with the correct infinitive or <i>-ing</i> form of the verbs below. Sometimes both forms are correct.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Our teacher first taught us some vocabulary and then went on #(explain) the meaning of the tenses.<br>
            <b>2</b>&ensp;Please remember #(take) your keys with you when you go out tonight.<br>
            <b>3</b>&ensp;Jack will always remember #(score) his first goal for the school football team.<br>
            <b>4</b>&ensp;We’ll stop #(have) a break at 11 a.m. and then we’ll return to working on the project.<br>
            
          </div>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
