import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "cover your mouth",
          "cross yourlegs",
          "give a thumbs up point",
          "scratch your head",
          "shrug your shoulders",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Match the sentence halves.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;This is supposed<br>
                <b>2</b>&ensp;It can<br>
                <b>3</b>&ensp;You’ll be able to<br>
                <b>4</b>&ensp;He needn’t<br>
                <b>5</b>&ensp;It can’t<br>
                <b>6</b>&ensp;Everyone ought<br>
              </div>
              <div style='margin:0px 50px'>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
                <input text-align=center border="2px solid" border-radius=5px width=40px height=40px maxlength=1 /><br>
              </div>
              <div>
                 <b>a</b>&ensp;take a boat trip tomorrow if the weather improves.<br>
                 <b>b</b>&ensp;be easy travelling on your own as you may get lonely.<br>
                 <b>c</b>&ensp;to be the most beautiful city in the world.<br>
                 <b>d</b>&ensp;to keep an open mind about other cultures.<br>
                 <b>e</b>&ensp;be extremely hot in Australia at Christmas.<br>
                 <b>f</b>&ensp;check out of the hotel yet.<br>
               </div>
            </div>

            
            
          `,
        answer: ["c", "e", "a", "f", "b", "d"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E2",
    // audio: "Audios/1.13.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E2answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 200, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["bite", "frown", "grin", "nod", "shake", "yawn"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["Introducing the other side of the argument"],
        styleElement: { color: "black", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct verbs (a–d) to complete the text.
`,
        color: "black",
        left: -20,
        width: "27cm",
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "2px solid",
          padding: "0px 4px",
          // color: "transparent",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 4px",

          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-4", "1-12", "2-4", "3-0", "4-4", "5-8", "6-8"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page25/E2/1.jpg" style='height:18cm'/>

      <div style='display:flex'>  
        <div style='margin-right:200px'>  
        <div style='display:flex'>  
          <b>1</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=0 type=Circle ></div>
                <div style='margin-left:10px'>ought to <br> can <br> must <br> should</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=1 type=Circle ></div>
                <div style='margin-left:10px'>need <br> might not <br> mustn’t <br> ought to</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=2 type=Circle ></div>
                <div style='margin-left:10px'>must <br> needn’t <br> has to <br> may</div>
             </div>
          </div>
           <div style='display:flex'>
            <b>4</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=3 type=Circle ></div>
                <div style='margin-left:10px'>could <br> can <br> should <br> are able to</div>
             </div>
          </div>
        </div>
        <div>
         
          <div style='display:flex'>
            <b>5</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=4 type=Circle ></div>
                <div style='margin-left:10px'>should <br> mustn’t <br> don’t need to <br> have to</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>6</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=5 type=Circle ></div>
                <div style='margin-left:10px'>may <br> are supposed to <br> shouldn’t <br> must</div>
             </div>
          </div>
          <div style='display:flex'>
            <b>7</b>
             <div style='display:flex;margin-left:15px' >
                <div><input id=6 type=Circle ></div>
                <div style='margin-left:10px'>can <br> have to <br> should <br> might</div>
             </div>
          </div>
          
          </div>
          </div>
          <div>
           

        `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      // padding: "1px 10px",
      // border: "2px solid",
      // borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      // marginLeft: 20,
      width: 200,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "disgust",
          "friendliness",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: ` Correct the mistakes in the sentences. Tick the correct
sentences.
`,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { marginLeft: 30, width: 600 },
        answers: [
          "can be",
          "",
          "must",
          "can't|can not",
          "can't|can not",
          "need not / do not need to / do not have to|needn't / don't need to / don't have to",
          "",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          marginLeft: 10,
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "6-0"],
        initialValue: [],
      },
      Layout: `

            <div style='line-height:45px;width:27cm'>
            <b>1</b>&ensp;The winters are able to be very cold in Austria.<input id=0 type=Circle /> <br> # <br>
            <b>2</b>&ensp;When we visit Norway next month, we should see the Northern Lights.<input id=1 type=Circle /> <br> # <br>
            <b>3</b>&ensp;This needs be the right house – it’s got a green door and blue curtains, just as Lara described.<input id=2 type=Circle /> <br> # <br>
            <b>4</b>&ensp;That man mustn’t be Ella’s dad – he looks too old.<input id=3 type=Circle /> <br> # <br>
            <b>5</b>&ensp;This can be the last time I see you! I have to see you again!<input id=4 type=Circle /> <br> # <br>
            <b>6</b>&ensp;You have to wait for me if you’re in a hurry – I’ll see you there!<input id=5 type=Circle /> <br> # <br>
            <b>7</b>&ensp;In a year’s time, I’ll be able to drive.<input id=6 type=Circle /> <br> # <br>
            </div>

  `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E4",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E4answerKey.png",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      // resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the second sentence so that it means the same
as the first. Use modal verbs.

`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 350, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `

              <div style="width:27cm">
                <b>1</b>&ensp;Sometimes our neighbours are very noisy. Our neighbours # very noisy.<br>
                <b>2</b>&ensp;You may not have another opportunity to see this film. This # last opportunity to see this film.<br>
                <b>3</b>&ensp;I’m sure we’re not late because we left on time. We left on time so # late.<br>
                <b>4</b>&ensp;Surely you recognise that woman from TV? # that woman from TV!<br>
                <b>5</b>&ensp;I hope it’ll be possible to go skiing in the winter. I hope I # go skiing in the winter.<br>
                <b>6</b>&ensp;It’s possible that tomorrow’s match will be cancelled. Tomorrow’s match # cancelled.<br>
                
              </div>
              
             
            
            
          `,
        // InputRong: true,
        answer: [
          "can be|can sometimes be",
          "may / might / could be your",
          "we can't be|we can not be",
          "You must recognise",
          "will be able to",
          "may / might / could be",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E5",
    // audio: "Audios/1.14.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E5answerKey.png",
    component: T6,
    textAlign: "center",
    textareaStyle: {
      // resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 150, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["able", "might", "mustn’t", "needn’t", "should (x2)", "supposed"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Complete the text with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

        <div style='width:20cm'>
            Every country around the world has its own customs
            and traditions, usually connected with bringing good
            luck or trying to avoid bad luck. For example, in
            Japan, if a beggar comes to your house, you are
            <sup>1</sup># to throw salt where they’ve been,
            otherwise you <sup>2</sup># have bad luck. In Brazil,
            you <sup>3</sup># place a small container of salt in
            the corner of the house and this <br><sup>4</sup># bring
            you good luck. In India, if there is a birth or death,
            the family members <sup>5</sup># go to a temple or
            light a lamp in the house for fifteen days. Unmarried
            people in Russia should avoid sitting at the corner
            of the table or they won’t be <br><sup>6</sup># to find a
            life partner. And in Viet Nam, you <br><sup>7</sup># take
            an expensive gift to a wedding; in fact, cash can be
            offered as a congratulatory gift.
        </div>

          `,
        answer: [
          "supposed",
          "might",
          "should",
          "should",
          "mustn't|must not",
          "able",
          "needn't|need not",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E6answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      marginLeft: 20,
      background: "none",
      width: 600,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page25/E6/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:70px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:154px;left:70px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:197px;left:70px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E7answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 800,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Think of one more topic to add and prepare a diplomatic
phrase to use.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         #
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P25-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page25/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    // textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Now do the task using your notes from exercises 6 and 7. `,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
};

export default json;
