import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E1",
    // audio: "Audios/1.03.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 720,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      width: 167,
      paddingTop: 7,
    },
    hintBox: [
      {
        src: [
          "combat",
          "commit",
          "cut",
          "petty",
          "prevention",
          "rate",
          "violent",
          "wave",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Complete the newspaper article with the words below.
`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `


        <div style='position:relative'>
        
        <img src="img/FriendsPlus/Page21/E1/1.jpg" style='height:15cm'/>
        <div style='position:absolute;top:111px;left:66px'>#</div>
        <div style='position:absolute;top:146px;left:212px'>#</div>
        <div style='position:absolute;top:181px;left:212px'>#</div>
        <div style='position:absolute;top:181px;left:458px'>#</div>
        <div style='position:absolute;top:250px;left:68px'>#</div>
        <div style='position:absolute;top:320px;left:208px'>#</div>
        <div style='position:absolute;top:390px;left:134px'>#</div>
        <div style='position:absolute;top:460px;left:121px'>#</div>
        </div>
          `,
        answer: [
          "cut",
          "violent",
          "rate",
          "petty",
          "wave",
          "combat",
          "prevention",
          "commit",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E2",
    // audio: "Audios/2-08.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",
    component: T6,
    // textAlign: "center",
    // stylesTextInput: {
    //   borderRadius: 5,
    //   border: "2px solid",
    //   marginLeft: 20,
    //   background: "none",
    //   width: 40,
    //   height: 40,
    //   paddingTop: 8,
    // },
    // maxLength: 1,
    stylesTextInput: { width: 800, paddingTop: 8 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
    },
    hintBox: [
      {
        src: ["drive", "go", "live", "lose", "make"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the article. What problem could the technology
mentioned in the article cause?`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2 ></textarea>
        </div>



         <div style='width:25cm'>
          <b>A</b>&ensp;Crime prevention software called PredPol helps the police predict where
crimes are likely to occur. The software analyses years of data on the location,
time and nature of crimes committed and predicts where and when crime is
most likely to occur in the future. Police officers receive this information on
the computers in their cars and they then spend more time in these areas. As
a result, the increased police presence in these neighbourhoods has cut crime
significantly. Crime figures for one area of Los Angeles, USA, were reduced by
12% in 2011 when police officers used crime prediction software. In the same
year, burglaries were cut by 26% in Manchester, UK, using the same methods.<br>
          <b>B</b>&ensp;As well as software that predicts crime, companies are also developing
technology that will help police patrols catch criminals before they can leave
town. By combining information such as the places where crimes frequently
take place with the routes that allow the fastest escape, computers will direct
patrols to where they can arrest criminals.<br>
          <b>C</b>&ensp;However, although the makers of this technology claim it will help reduce
crime rates, some people are not so sure. Not all crime is reported and if the
police just focus on the neighbourhoods where most crime occurs, they will
neglect others. Some experts claim that if police officers just pay attention
to the information that has been stored in their devices, crime rates could
actually rise in some areas.<br>
          

        </div>
          `,
        answer: [
          "It could cause crime to increase in some areas if the police only concentrate on reported crimes.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",
    component: T6,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: {
      padding: "1px 10px",
      border: "2px solid",
      borderRadius: 5,
      // color: "transparent",
      // borderColor: "black",
      // fontWeight: "bold",
      width: 40,
      height: 40,
      paddingTop: 5,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#42429C'>Reading Strategy</span>. Match paragraphs (A–C) with
questions (1–6) below. Each paragraph may be matched
with more than one question.
`,
        width: "27cm",
        color: "black",
        left: 50,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Which paragraph mentions …</b>
            <div style='display:flex;margin-bottom:20px;line-height:50px'>
              <div>
                <b>1</b>&ensp;the disadvantages of a new technology? <br>
                <b>2</b>&ensp;successful results from new technology? <br>
                <b>3</b>&ensp;stopping criminals from getting away? <br>
                <b>4</b>&ensp;a reduction in UK crime? <br>
                <b>5</b>&ensp;police ignoring crime in some cases? <br>
                <b>6</b>&ensp;a possible increase in crime rates? <br>
              </div>
              <div style='margin-left:100px'>
                #<br>
                #<br>
                #<br>
                #<br>
                #<br>
                #<br>
              </div>
            </div>
            <div style='width:25cm'>
          <b>A</b>&ensp;Crime prevention software called PredPol helps the police predict where
crimes are likely to occur. The software analyses years of data on the location,
time and nature of crimes committed and predicts where and when crime is
most likely to occur in the future. Police officers receive this information on
the computers in their cars and they then spend more time in these areas. As
a result, the increased police presence in these neighbourhoods has cut crime
significantly. Crime figures for one area of Los Angeles, USA, were reduced by
12% in 2011 when police officers used crime prediction software. In the same
year, burglaries were cut by 26% in Manchester, UK, using the same methods.<br>
          <b>B</b>&ensp;As well as software that predicts crime, companies are also developing
technology that will help police patrols catch criminals before they can leave
town. By combining information such as the places where crimes frequently
take place with the routes that allow the fastest escape, computers will direct
patrols to where they can arrest criminals.<br>
          <b>C</b>&ensp;However, although the makers of this technology claim it will help reduce
crime rates, some people are not so sure. Not all crime is reported and if the
police just focus on the neighbourhoods where most crime occurs, they will
neglect others. Some experts claim that if police officers just pay attention
to the information that has been stored in their devices, crime rates could
actually rise in some areas.<br>
          

        </div>
          `,
        answer: ["C", "A", "B", "A", "C", "C"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E4",
    // audio: "Audios/1.05.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E4answerKey.png",
    textareaStyle: {
      width: 300,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `Correct the mistakes in the phrases in bold. Tick the
correct sentences.
`,
        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,

    // hideBtnFooter:true,
    // textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "call off",
          "let down",
          "look after",
          "run into",
          "take after",
          "turn down",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    // character: "",
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 200, textAlign: "center" },
        answers: [
          "by the time",
          "",
          "in the end ",
          "at the end ",
          "at the time",
          "",
        ],
        initialValue: [],
      },
      Circle: {
        TickRong: true,
        initialWordStyle: {
          padding: "1px 10px",
          border: "2px solid",
          borderRadius: 5,
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          borderRadius: 5,
          // borderRadius: "50%",
          color: "black",
          // textDecoration: "line-through",
          border: "2px solid",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓", "✓", "✓"],
        answers: ["1-0", "5-0"],
        initialValue: [],
      },
      Layout: `

            <div style='line-height:50px;width:32cm'>
              <b>1</b>&ensp;It was a long day and <b>at the time</b> I got home I was exhausted. # <input id=0 type=Circle /> <br>
            <b>2</b>&ensp;The show doesn’t start until eight, so we should still get there <b>in time</b>. # <input id=1 type=Circle /> <br>
            <b>3</b>&ensp;Joe and Lisa were always arguing and <b>at the end</b> they split up. # <input id=2 type=Circle /> <br>
            <b>4</b>&ensp;It’s only <b>by the end</b> of the book that you learn the killer’s identity. # <input id=3 type=Circle /> <br>
            <b>5</b>&ensp;I didn’t realise it <b>by the time</b>, but the girl we met last night was Terry’s sister. # <input id=4 type=Circle /> <br>
            <b>6</b>&ensp;We should have moved house <b>by the end</b> of the month. # <input id=5 type=Circle /> <br>
            </div>

  `,
    },
  },
  5: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E5",
    // audio: "Audios/1.06.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E5answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    textareaStyle: {
      resize: "none",
      marginBottom: -20,
      // marginLeft: 10,
      width: 700,
      paddingTop: 1,
    },
    stylesTextInput: { width: 658, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      width: "20cm",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["a", "both", "few", "many", "no", "none", "of", "one"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Choose the correct words to complete the text.  
`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // character: "/",
    // isHello: true,
    // KeyMau: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a_while / one_thing",
          "effect / common",
          "fault / last",
          "vain / control",
          "time / doubt",
          "control / the_impression",
          "time / fact",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-4", "5-4", "6-0"],
        initialValue: [],
      },
      Layout: `
            <div style='width:26cm'>
              How do you stop being friends with
              someone you no longer feel close to?
              If you’ve been friends for <sup>1</sup><input id=0 type=Circle />, you may simply not have
              anything in <sup>2</sup><input id=1 type=Circle /> any
              more. It doesn’t mean anyone’s at
              <sup>3</sup><input id=2 type=Circle />. If you’ve tried in <sup>4</sup><input id=3 type=Circle /> to make things work, maybe it’s
              best to end the friendship. Naturally, you don’t want to hurt the
              other person, and if you’re in any <sup>5</sup><input id=4 type=Circle />, don’t say or do
              anything you might regret. Perhaps your friend doesn’t realise
              how you feel – they may be under <sup>6</sup><input id=5 type=Circle /> 
              that everything’s fine. You could talk to them and suggest not
              seeing each other for a few weeks, and in <sup>7</sup><input id=6 type=Circle /> you’ll know
              whether you want your friendship to continue or not.
            </div>
              
        `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E6",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E6answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      // marginLeft: 20,
      background: "none",
      width: 150,
      paddingTop: 8,
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["as", "far", "fewer", "like", "more", "nowhere", "than", "the"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#92278f'>Vocab boost!</span> box.  Complete the sentences with
appropriate nouns and your own ideas.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page21/E6/1.jpg" style='height:7cm'/>
        <div style='width:26cm'>
            <b>1</b>&ensp;Although I haven’t done it for a #, I used to like #.<br>
            <b>2</b>&ensp;At the #, my favourite song is #.<br>
            <b>3</b>&ensp;The last time I was late for something was #. I couldn’t get there on # because #.<br>
            <b>4</b>&ensp;In the future, I’d love to #. With any #, I’ll be able to.<br>
        </div>
          `,
        answer: [
          "while",
          "while",
          "moment",
          "moment",
          "time",
          "time",
          "time",
          "luck ",
          "luck ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E7",
    // audio: "Audios/2-11.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E7answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      width: 300,
      paddingTop: 8,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    textareaStyle: {
      // resize: "none",
      // marginBottom: -20,
      // marginLeft: 10,
      width: 760,
      paddingTop: 1,
    },
    hintBox: [
      {
        src: ["be", "fall", "go", "snow", "spend", "take", "turn", "into"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `Write your essay.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea id=0 rows=7 ></textarea>
          
          `,
        answer: [
          "going / to go",
          "being",
          "snowing / to snow",
          "falling",
          "to take",
          "turning into / to turn into",
          "spending",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "12WB-2024-U2-P21-E3",
    // audio: "Audios/1.07.mp3",
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // hideBtnFooter: true,
    textAlign: "center",
    // maxLength: 1,
    textareaStyle: {
      width: 250,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { background: "none", width: 250, paddingTop: 7 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      fontWeight: "bold",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a business",
          "a family",
          "divorced",
          "engaged",
          "home",
          "married",
          "school (x2)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ` `,
        width: "27cm",
        color: "black",
        left: -20,
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 10px",
          // border: "2px solid",
          color: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 10px",
          // borderRadius: "50%",
          color: "black",
          // borderBottom: "2px solid",
        },
        limitSelect: 4,
        listWords: [`✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `


      <div style='position:relative'>
        <img src="img/FriendsPlus/Page21/E8/1.jpg" style='height:7cm'/>
        <div style='position:absolute;top:111px;left:80px'><input id=0 type=Circle /></div>
        <div style='position:absolute;top:157px;left:80px'><input id=1 type=Circle /></div>
        <div style='position:absolute;top:201px;left:80px'><input id=2 type=Circle /></div>
      </div>

     

        
        `,
    },
  },
};

export default json;
